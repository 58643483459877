<template>
  <div id="apork-about-area" class="apork-about-area mt-230">
    <!-- SECTION-01 -->
    <div class="container">
      <div class="homeTitle">
        <h3 style="font-weight: 800; font-size: 25px">
          💡 NEW 새로 등록된 컨텐츠
        </h3>
      </div>
      <div class="row test homebot-02">
        <product
          v-for="(product, index) in products1"
          :key="`product${index}`"
          :product="product"
        />
      </div>
    </div>

    <!-- SECTION-02 -->
    <div class="main-bg">
      <div class="container">
        <div class="homeTitle">
          <h3 style="font-weight: 800; font-size: 25px">
            🔥 BEST 트레이닝 프로그램
          </h3>
          <a href="/product-list-best" class="plus-a">더보기 +</a>
        </div>
        <div class="row test">
          <product
            v-for="(product, index) in products2"
            :key="`product${index}`"
            :product="product"
          />
        </div>
      </div>
    </div>

    <!-- SECTION-03 -->
    <div class="container">
      <div class="homeTitle-02">
        <h3 style="font-weight: 800; font-size: 25px">
          ⚽️ 강의 보고 실력을 키워보자!
        </h3>
        <a href="/product-list-education" class="plus-a">더보기 +</a>
      </div>
      <div class="row test homebot-02">
        <product
          v-for="(product, index) in products3"
          :key="`product${index}`"
          :product="product"
        />
      </div>
    </div>

    <!-- SECTION-04 -->
    <div class="main-bg">
      <div class="container">
        <div class="homeTitle-03">
          <h3 style="font-weight: 800; font-size: 25px">
            🎈 프로 선수들은 어떤 훈련을 할까?
          </h3>
          <a href="/product-list-pro" class="plus-a">더보기 +</a>
        </div>
        <div class="row">
          <div class="main-img-wraper homebot">
            <div class="about-img main-img">
              <img src="@/assets/img/new/main-img.png" alt="about-1" />
            </div>
            <div class="main-size-02">
              <router-link to="/product-list-pro">
                <div class="main-product product-16"></div>
                <a href="/" class="main-product-a product-margin-b"
                  >[FC Barcelona] 23-24 시즌 첫 오픈 트레이닝</a
                >
                <p class="main-product-p">
                  스페인 1부 리그 라리가 명문팀 바르셀로나의 시즌 첫 훈련
                </p>
              </router-link>
            </div>
            <div class="main-size-02">
              <router-link to="/product-list-pro">
                <div class="main-product product-17"></div>
                <a href="/" class="main-product-a product-margin-b"
                  >[Liverpool FC] 알리송을 위한 첫 번째 세션</a
                >
                <p class="main-product-p">
                  세계 최고의 축구 리그 EPL의 전통적인 축구팀 리버풀의 수문장
                  알리송의 훈련
                </p>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- SECTION-05 -->
    <div class="container">
      <div class="homeTitle-02">
        <h3 style="font-weight: 800; font-size: 25px">
          🎯 유소년을 위한 훈련들만 모았다!
        </h3>
        <a href="/product-list-youth" class="plus-a">더보기 +</a>
      </div>
      <div class="row">
        <div class="main-img-wraper">
          <div class="main-size-02">
            <router-link to="/product-list-youth">
              <div class="main-product product-18"></div>
              <a href="/" class="main-product-a product-margin-b"
                >[KFA] 인지능력 향상 4편</a
              >
              <p class="main-product-p">
                오성환 코치가 알려주는 인지능력 향상 4편
              </p>
            </router-link>
          </div>
          <div class="main-size-02">
            <router-link to="/product-list-youth">
              <div class="main-product product-19"></div>
              <a href="/" class="main-product-a product-margin-b"
                >[KFA 아카데미] 드리블&페인팅</a
              >
              <p class="main-product-p">
                KFA 전임 지도자 송승준 코치가 알려주는 유소년 드리블 훈련
              </p>
            </router-link>
          </div>
          <div class="about-img main-img">
            <img src="@/assets/img/new/main-img-02.png" alt="about-1" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Product from "./Product.vue";
export default {
  components: { Product },
  name: "AboutOne",
  data() {
    return {
      sec_title: "축구인을 위한 트레이닝 플랫폼 '스포바이'",
      sec_para:
        "다양한 트레이닝 프로그램, 전술, 강의 영상 등 전 세계 축구 데이터가 여기 다 있다!",
      title:
        "이제는 온라인으로 전 세계 축구 관련 모든 데이터를 <br>손쉽게 찾아보고 배워보자 <span>스포바이!</span>",
      paragraph:
        "훈련 프로그램 및 교육 자료의 데이터 정량화를 통해 개인과 팀의 성장을 돕고, 전문 체육부터 생활 체육까지 다양한 훈련법, 교육 강의, 멘토링 서비스를 제공합니다. 온라인 '트레이닝 제작 툴'을 통해 여러분은 손쉽게 자신만의 훈련 프로그램을 제작하고 공유할 수 있습니다. 또한, '프로그램 쉐어링' 기능을 통해 전략과 훈련 프로그램을 등록하고 판매함으로써 지혜를 나누고 경제적 이익을 얻을 수 있습니다.",
      btn: "let's go spoby",

      products1: [
        {
          id: 1,
          title: "[FC Seoul] 2023시즌 울산전 대비 훈련",
          description: "K리그 울산과의 홈경기 대비 훈련",
          image: require("/src/assets/img/new/youtube-22.png"),
          image2: require("/src/assets/img/sub-img/youtube-22-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-22-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=Km9Q-2e6HKU&t=453s",
          downloadUrl: "/video/[FC Seoul] 2023시즌 울산전 대비 훈련.mp4",
          length: "11:07",
          number: "96",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 2,
          title: "[30분 코스] 패스와 컨트롤 훈련",
          description: "패스와 컨트롤 간단한 훈련 10가지",
          image: require("/src/assets/img/new/youtube-03.png"),
          image2: require("/src/assets/img/sub-img/youtube-03-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-03-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=F7e0QpvF9fA&t=7s",
          downloadUrl: "/video/[30분 코스] 패스와 컨트롤 훈련.mp4",
          length: "06:36",
          number: "204",
          rank: "무료",
          strength: "중",
          participation: "1명",
          age: "10 ~ 20",
        },
        {
          id: 3,
          title: "[드리블+슈팅] 6인 1조",
          description:
            "6명씩 한 그룹으로 드리블과 슈팅까지 한번에 연결하는 훈련!",
          image: require("/src/assets/img/new/insta-02.png"),
          image2: require("/src/assets/img/sub-img/insta-02-01.png"),
          image3: require("/src/assets/img/sub-img/insta-02-02.png"),
          youtubeUrl: "https://youtu.be/mexd9Y_5Aos",
          downloadUrl: "/video/[드리블+슈팅] 6인 1조.mp4",
          length: "00:08",
          number: "131",
          rank: "무료",
          strength: "중하",
          participation: "그룹 - 6명",
          age: "10 ~ 19",
        },
        {
          id: 4,
          title: "풋워크 + 스텝",
          description: "측면 점프와 멀리뛰기를 결합한 코디네이션",
          image: require("/src/assets/img/new/insta-13.png"),
          image2: require("/src/assets/img/sub-img/insta-13-01.png"),
          image3: require("/src/assets/img/sub-img/insta-13-02.png"),
          youtubeUrl: "https://youtu.be/dIWluMwnsYU",
          downloadUrl: "/video/풋워크 + 스텝.mp4",
          length: "00:15",
          number: "295",
          rank: "무료",
          strength: "중",
          participation: "1명",
          age: "13 ~ 16",
        },
        {
          id: 5,
          title: "소프트한 터치를 위한 스킬",
          description: "마커 3개만 있으면 되는 1인 드리블 훈련",
          image: require("/src/assets/img/new/insta-15.png"),
          image2: require("/src/assets/img/sub-img/insta-15-01.png"),
          image3: require("/src/assets/img/sub-img/insta-15-02.png"),
          youtubeUrl: "https://youtu.be/gtI9CiEknD0",
          downloadUrl: "/video/소프트한 터치를 위한 스킬.mp4",
          length: "00:21",
          number: "221",
          rank: "무료",
          strength: "중하",
          participation: "1명",
          age: "9 ~ 16",
        },
      ],
      products2: [
        {
          id: 6,
          title: "[KFA 골든에이지] 골키퍼 빌드업",
          description: "골키퍼가 빌드업을 하기 위해서 가장 중요한 요소",
          image: require("/src/assets/img/new/youtube-23.png"),
          image2: require("/src/assets/img/sub-img/youtube-23-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-23-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=CbJMtXY7KsA&t=35s",
          downloadUrl: "/video/[KFA 골든에이지] 골키퍼 빌드업.mp4",
          length: "07:11",
          number: "773",
          rank: "무료",
          strength: "중하",
          participation: "1 ~ 3명",
          age: "11 ~ 16",
        },
        {
          id: 7,
          title: "Passing Combinations",
          description: "패싱을 향상시키는 스핀 패싱 프로그램",
          image: require("/src/assets/img/new/insta-11.png"),
          image2: require("/src/assets/img/sub-img/insta-11-01.png"),
          image3: require("/src/assets/img/sub-img/insta-11-02.png"),
          youtubeUrl: "https://youtu.be/SQKSkgXkWI4",
          downloadUrl: "/video/Passing Combinations.mp4",
          length: "00:35",
          number: "422",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 5명",
          age: "13 ~ 20",
        },
        {
          id: 8,
          title: "혼자서 하는 드리블링",
          description: "풋워크와 드리블을 결합한 1인 훈련 프로그램",
          image: require("/src/assets/img/new/insta-14.png"),
          image2: require("/src/assets/img/sub-img/insta-14-01.png"),
          image3: require("/src/assets/img/sub-img/insta-14-02.png"),
          youtubeUrl: "https://youtu.be/PeXuqbZZu_0",
          downloadUrl: "/video/혼자서 하는 드리블링.mp4",
          length: "00:17",
          number: "241",
          rank: "무료",
          strength: "중상",
          participation: "1명",
          age: "13 ~ 20",
        },
        {
          id: 9,
          title: "Finishing Circuit",
          description: "골 결정력 강화를 위한 서킷",
          image: require("/src/assets/img/new/insta-09.png"),
          image2: require("/src/assets/img/sub-img/insta-09-01.png"),
          image3: require("/src/assets/img/sub-img/insta-09-02.png"),
          youtubeUrl: "https://youtu.be/gsaoQyI4QsU",
          downloadUrl: "/video/Finishing Circuit.mp4",
          length: "00:37",
          number: "587",
          rank: "무료",
          strength: "중상",
          participation: "그룹 - 9명",
          age: "13 ~",
        },
        {
          id: 10,
          title: "1 v 1 Games",
          description: "스프린트, 헤딩 1vs1 레이스",
          image: require("/src/assets/img/new/insta-10.png"),
          image2: require("/src/assets/img/sub-img/insta-10-01.png"),
          image3: require("/src/assets/img/sub-img/insta-10-02.png"),
          youtubeUrl: "https://youtu.be/IuOPkyAPlLg",
          downloadUrl: "/video/1 v 1 Games.mp4",
          length: "00:30",
          number: "608",
          rank: "무료",
          strength: "중상",
          participation: "그룹 - 4명",
          age: "12 ~ 20",
        },
      ],
      products3: [
        {
          id: 11,
          title: "[기본기] 인사이드 패스 강좌",
          description: "축구에서 가장 중요한 기본기-패스",
          image: require("/src/assets/img/new/youtube-04.png"),
          image2: require("/src/assets/img/sub-img/youtube-04-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-04-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=cR9Dspq-Kjo&t=311s",
          downloadUrl: "/video/[기본기] 인사이드 패스 강좌.mp4",
          length: "06:25",
          number: "109",
          rank: "무료",
          strength: "하",
          participation: "1 ~ 2명",
          age: "8 ~ 20",
        },
        {
          id: 12,
          title: "프로 19년차가 알려주는 롱킥",
          description: "롱킥은 힘으로만 차는 게 아니라 임팩트가 중요합니다.",
          image: require("/src/assets/img/new/youtube-05.png"),
          image2: require("/src/assets/img/sub-img/youtube-05-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-05-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=R5JNBLYni70&t=87s",
          downloadUrl: "/video/프로 19년차가 알려주는 롱킥.mp4",
          length: "12:09",
          number: "52",
          rank: "무료",
          strength: "중하",
          participation: "1 ~ 2명",
          age: "17 ~",
        },
        {
          id: 13,
          title: "[세뇰킴] 사비 알론소처럼 롱킥 차는법",
          description: "6명씩 한 그룹을 만들어서 2대1 패스 훈련을 하는 방법은?",
          image: require("/src/assets/img/new/youtube-06.png"),
          image2: require("/src/assets/img/sub-img/youtube-06-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-06-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=H83K7Zyu0P4",
          downloadUrl: "/video/[세뇰킴] 사비 알론소처럼 롱킥 차는법.mp4",
          length: "02:01",
          number: "409",
          rank: "무료",
          strength: "중하",
          participation: "1명",
          age: "16 ~ 20",
        },
        {
          id: 14,
          title: "[세뇰킴] 골대 앞에서 정확하게 슈팅하는 법",
          description: "6명씩 한 그룹을 만들어서 2대1 패스 훈련을 하는 방법은?",
          image: require("/src/assets/img/new/youtube-07.png"),
          image2: require("/src/assets/img/sub-img/youtube-07-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-07-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=y1C07lp3I38&t=31s",
          downloadUrl: "/video/[세뇰킴] 골대 앞에서 정확하게 슈팅하는 법.mp4",
          length: "02:33",
          number: "102",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 6명",
          age: "13 ~ 20",
        },
        {
          id: 15,
          title: "[조세민의 레슨] 드리블 강좌",
          description: "100% 상대를 이길 수 있는 드리블 기술",
          image: require("/src/assets/img/new/youtube-08.png"),
          image2: require("/src/assets/img/sub-img/youtube-08-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-08-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=VOi5ZqWxpC4&t=3s",
          downloadUrl: "/video/[조세민의 레슨] 드리블 강좌.mp4",
          length: "04:05",
          number: "262",
          rank: "무료",
          strength: "중하",
          participation: "1 ~ 2명",
          age: "10 ~ 16",
        },
      ],
    };
  },
};
</script>

<style scoped>
.main-size {
  width: 100%;
  flex: 1;
}

.homeTitle {
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}

.homeTitle-02 {
  margin-top: 60px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.homeTitle-03 {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.homebot {
  margin-bottom: 60px;
}

.homebot-02 {
  margin-bottom: 30px;
}
</style>
