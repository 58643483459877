<template>
  <div>
    <HeaderOne />
    <div class="product-detail-best">
      <Breadcrumb
        title="GRAND OPENING"
        sub-title="모든 자료를 무료로 즐겨보세요."
      />

      <div class="contents-box-wraper">
        <div class="contents-box">
          <!-- 사이드 바 시작 -->
          <div class="side-nav-wraper">
            <div class="side-nav-line"></div>
            <h3 class="side-nav-title">인기 카테고리</h3>
            <ul class="side-nav-item">
              <li class="nav-li">
                <a href="/product-list" class="nav-li-02-a">전체</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-best" class="nav-li-03-a">BEST</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-program" class="nav-li-04-a"
                  >프로그램</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-education" class="nav-li-05-a"
                  >교육 강의</a
                ><span>></span>
              </li>
              <div class="side-nav-item-bg-01">
                <li class="nav-li-01">
                  <a href="/product-list-pro" class="nav-li-01-a">프로 구단</a
                  ><span>></span>
                </li>
              </div>
              <li class="nav-li">
                <a href="/product-list-youth" class="nav-li-06-a">유소년</a
                ><span>></span>
              </li>
            </ul>
          </div>

          <!-- 메인 컨텐츠 시작 -->
          <div class="main-con-wraper">
            <div class="main-con-bg">
              <product
                v-for="(product, index) in products"
                :key="`product${index}`"
                :product="product"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import HeaderOne from "../Header.vue";
import Footer from "../Footer.vue";
import Breadcrumb from "../Breadcrumb.vue";
import Product from "../Product.vue";

export default {
  name: "ProductListPro",
  components: { HeaderOne, Footer, Breadcrumb, Product },
  data() {
    return {
      products: [
        {
          id: 1,
          title: "[Barcelona] 23-24 시즌 첫 오픈 트레이닝",
          description: "스페인 라리가 명문팀 바르셀로나의 시즌 첫 훈련",
          image: require("/src/assets/img/new/youtube-09.png"),
          image2: require("/src/assets/img/sub-img/youtube-09-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-09-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=AYmGGidxncI&t=37s",
          downloadUrl: "/video/[Barcelona] 23-24 시즌 첫 오픈 트레이닝.mp4",
          length: "04:12",
          number: "198",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 2,
          title: "[Liverpool] 알리송을 위한 첫 번째 세션",
          description: "EPL의 전통적인 축구팀 리버풀의 수문장 알리송의 훈련",
          image: require("/src/assets/img/new/youtube-10.png"),
          image2: require("/src/assets/img/sub-img/youtube-10-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-10-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=VMbhF-whpX4",
          downloadUrl: "/video/[Liverpool] 알리송을 위한 첫 번째 세션.mp4",
          length: "18:29",
          number: "225",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 3,
          title: "[Real Madrid] 오픈 트레이닝",
          description: "챔스 DNA, 세계 최고의 클럽의 오픈 트레이닝 영상",
          image: require("/src/assets/img/new/youtube-17.png"),
          image2: require("/src/assets/img/sub-img/youtube-17-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-17-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=GF6j-tYCDRg",
          downloadUrl: "/video/[Real Madrid] 오픈 트레이닝.mp4",
          length: "03:05",
          number: "401",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 4,
          title: "[Manchester United] 23-24 프리시즌",
          description: "EPL 소속 맨체스터 유나이티드의 프리시즌 트레이닝",
          image: require("/src/assets/img/new/youtube-18.png"),
          image2: require("/src/assets/img/sub-img/youtube-18-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-18-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=NJM8brrlHo0&t=281s",
          downloadUrl: "/video/[Manchester United] 23-24 프리시즌.mp4",
          length: "09:52",
          number: "294",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 5,
          title: "[PSG] 23-24 트레이닝 세션",
          description: "리그앙 소속 파리 생제르망의 트레이닝",
          image: require("/src/assets/img/new/youtube-19.png"),
          image2: require("/src/assets/img/sub-img/youtube-19-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-19-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=1umHFX56l8c",
          downloadUrl: "/video/[PSG] 23-24 트레이닝 세션.mp4",
          length: "03:04",
          number: "527",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 6,
          title: "[Tottenham] 주말 경기 대비 트레이닝",
          description: "손흥민 선수가 있는 토트넘의 웨스트햄전 대비 트레이닝",
          image: require("/src/assets/img/new/youtube-20.png"),
          image2: require("/src/assets/img/sub-img/youtube-20-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-20-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=J1H-vRjYwRk",
          downloadUrl: "/video/[Tottenham] 주말 경기 대비 트레이닝.mp4",
          length: "03:52",
          number: "578",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 7,
          title: "[Arsenal] 23-24 트레이닝 세션",
          description: "EPL 소속 아스날의 피지컬 트레이닝",
          image: require("/src/assets/img/new/youtube-21.png"),
          image2: require("/src/assets/img/sub-img/youtube-21-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-21-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=CwgRMN6ipNw&t=186s",
          downloadUrl: "/video/[Arsenal] 23-24 트레이닝 세션.mp4",
          length: "07:29",
          number: "152",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 8,
          title: "[FC Seoul] 2023시즌 울산전 대비 훈련",
          description: "K리그 울산과의 홈경기 대비 훈련",
          image: require("/src/assets/img/new/youtube-22.png"),
          image2: require("/src/assets/img/sub-img/youtube-22-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-22-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=Km9Q-2e6HKU&t=453s",
          downloadUrl: "/video/[FC Seoul] 2023시즌 울산전 대비 훈련.mp4",
          length: "11:07",
          number: "96",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
      ],
    };
  },
};
</script>
<style>
.product-detail {
  height: 100%;
}
.contents-box-wraper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.contents-box {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* 사이드 바 */
.side-nav-wraper {
  margin-top: 40px;
  width: 11.5%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.side-nav-line {
  width: 100%;
  height: 5px;
  background-color: #002870;
  margin-bottom: 15px;
}
.side-nav-title {
  font-size: 25px;
  font-weight: 700;
}
.side-nav-item-bg-01 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(0, 40, 112, 0.1);
}
.side-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 100%;
}
.side-nav-item li {
  padding: 10px 0;
  font-size: 17px;
  font-weight: 500;
  width: 80%;
}

.nav-li {
  display: flex;
  justify-content: space-between;
}
.nav-li-01 {
  color: #002870;
  display: flex;
  justify-content: space-between;
}
.nav-li-01-a {
  color: #002870;
}
.nav-li-02-a,
.nav-li-03-a,
.nav-li-04-a,
.nav-li-05-a,
.nav-li-06-a {
  color: black;
}

/* 메인 콘텐츠 */
.main-con-wraper {
  margin-top: 40px;
  width: 80%;
  height: 100%;
}
.main-con-bg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* 콘텐츠 01 */
.con-img {
  border: 1px solid #969696;
  border-radius: 20px;
  height: 200px;
  background-image: url(@/assets/img/new/youtube-02.png);
  background-size: cover;
}
.main-con-item {
  width: 20%;
  height: 100%;
  margin-right: 30px;
  margin-bottom: 50px;
}
.main-con-txt {
  margin-top: 15px;
}
.main-con-a {
  color: black;
  font-size: 17px;
  font-weight: 700;
}
.main-con-p {
  color: #969696;
  font-size: 15px;
  font-weight: 500;
}
</style>
