<template>
  <div class="main-size" @click="moveToProduct(product.id)">
    <div
      class="main-product"
      :style="{ 'background-image': `url(${product.image})` }"
    />
    <a href="/" class="main-product-a product-margin-b">{{ product.title }}</a>
    <p class="main-product-p">
      {{ product.description }}
    </p>
  </div>
</template>
<script>
export default {
  /* eslint-disable */
  name: "Product",
  props: ["product"],
  methods: {
    moveToProduct(id) {
      this.$router.push({
        name: `product`,
        params: { id, product: this.product },
      });
    },
  },
};
</script>

<style lscoped>
.main-size {
  cursor: pointer;
}
.product-margin-b {
  margin-bottom: 5px;
}
</style>
