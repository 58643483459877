<template>
  <div>
    <!-- header area start -->
    <HeaderOne />
    <!-- header area end -->
    <!-- main area start -->
    <main>
      <!-- breadcrumb area start -->
      <Breadcrumb title="about" />
      <!-- breadcrumb area end -->
      <!-- about area start -->
      <div id="apork-about-area" class="apork-about-area mt-110 mb-110">
        <div class="container">
          <div class="row">
            



            <div class="col-xl-6 col-lg-6 wow fadeInLeft">
              <div class="about-img">
                <img src="@/assets/img/about/service-03.png" alt="about-1" />
              </div>
            </div>
            <div class="col-xl-6 col-lg-6 wow fadeInRight">
              <div class="about-content mt-110 mb-90">
                <img src="@/assets/img/about/about-icon-1.png" alt="" />
                <h3>
                  이제는 온라인으로 전 세계 축구 관련 모든 데이터를 <br>손쉽게 찾아보고 배워보자 <span>스포바이!</span>
                </h3>
                <p>
                  훈련 프로그램 및 교육 자료의 데이터 정량화를 통해 개인과 팀의 성장을 돕고, 전문 체육부터 생활 체육까지 다양한 훈련법, 교육 강의, 멘토링 서비스를 제공합니다. 온라인 '트레이닝 제작 툴'을 통해 여러분은 손쉽게 자신만의 훈련 프로그램을 제작하고 공유할 수 있습니다. 또한, '프로그램 쉐어링' 기능을 통해 전략과 훈련 프로그램을 등록하고 판매함으로써 지혜를 나누고 경제적 이익을 얻을 수 있습니다.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- about area end -->
      <!-- footer area start -->
      <FooterTwo />
      <!-- footer area end -->
    </main>
    <!-- main area end -->

    <!-- scrolltop button -->
    <a class="material-scrolltop" href="#"></a>
  </div>
</template>

<script>
import Breadcrumb from "../Breadcrumb.vue";
import HeaderOne from "../Header.vue";
import FooterTwo from "../Footer.vue";
export default {
  name: "AboutUs",
  components: { HeaderOne, Breadcrumb, FooterTwo },
  mounted() {
    document.addEventListener('scroll', this.topToBottom)
  },
  methods: {
    topToBottom() {
      const result = document.querySelector('.material-scrolltop')
      if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
        result.classList.add("reveal");
      } else {
        result.classList.remove("reveal");
      }
    },
  }
};
</script>

<style>

</style>
