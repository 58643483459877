var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"aprok-faq-area",attrs:{"id":"aprok-faq-area"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_vm._m(0),_c('div',{staticClass:"col-xl-6 col-lg-6 col-md-12 wow fadeInRight"},[_c('div',{staticClass:"aprok-accordion-wrapper"},[_c('div',{staticClass:"accordion",attrs:{"id":"accordionExample"}},[_c('div',{staticClass:"accordion-item"},[_c('div',{staticClass:"accordion-header",attrs:{"id":"headingOne"}},[_c('h2',{staticClass:"mb-0"},[_c('a',{staticClass:"btn btn-link",class:[_vm.showfaq === 1 ? '' : 'collapsed'],attrs:{"href":"#","data-bs-toggle":"collapse","data-bs-target":"#collapseOne","aria-expanded":[_vm.showfaq === 1 ? 'true' : 'false'],"aria-controls":"collapseOne"},on:{"click":function($event){$event.preventDefault();return _vm.faqlist(1)}}},[_vm._v(" 서비스 이용에 대한 비용이 있나요? ")])])]),_c('div',{class:[
                  _vm.showfaq === 1
                    ? 'accordion-collapse show'
                    : 'accordion-collapse collapse',
                ],attrs:{"id":"collapseOne","aria-labelledby":"headingOne","data-parent":"#accordionExample"}},[_vm._m(1)])]),_c('div',{staticClass:"accordion-item"},[_c('div',{staticClass:"accordion-header",attrs:{"id":"headingTwo"}},[_c('h2',{staticClass:"mb-0"},[_c('a',{staticClass:"btn btn-link",class:[_vm.showfaq === 2 ? '' : 'collapsed'],attrs:{"href":"#","data-bs-toggle":"collapse","data-bs-target":"#collapseTwo","aria-expanded":[_vm.showfaq === 2 ? 'true' : 'false'],"aria-controls":"collapseTwo"},on:{"click":function($event){$event.preventDefault();return _vm.faqlist(2)}}},[_vm._v(" 내 레벨에 맞는 훈련 프로그램을 어떻게 찾을 수 있나요? ")])])]),_c('div',{class:[
                  _vm.showfaq === 2
                    ? 'accordion-collapse show'
                    : 'accordion-collapse collapse',
                ],attrs:{"id":"collapseTwo","aria-labelledby":"panelsStayOpen-headingTwo"}},[_vm._m(2)])]),_c('div',{staticClass:"accordion-item"},[_c('div',{staticClass:"accordion-header",attrs:{"id":"headingThree"}},[_c('h2',{staticClass:"mb-0"},[_c('a',{staticClass:"btn btn-link",class:[_vm.showfaq === 3 ? '' : 'collapsed'],attrs:{"href":"#","data-bs-toggle":"collapse","data-bs-target":"#collapseThree","aria-expanded":[_vm.showfaq === 3 ? 'true' : 'false'],"aria-controls":"collapseThree"},on:{"click":function($event){$event.preventDefault();return _vm.faqlist(3)}}},[_vm._v(" 훈련 제작 툴을 사용하려면 어떻게 해야 하나요? ")])])]),_c('div',{class:[
                  _vm.showfaq === 3
                    ? 'accordion-collapse show'
                    : 'accordion-collapse collapse',
                ],attrs:{"id":"collapseThree","aria-labelledby":"panelsStayOpen-headingThree"}},[_vm._m(3)])]),_c('div',{staticClass:"accordion-item"},[_c('div',{staticClass:"accordion-header",attrs:{"id":"headingFour"}},[_c('h2',{staticClass:"mb-0"},[_c('a',{staticClass:"btn btn-link",class:[_vm.showfaq === 4 ? '' : 'collapsed'],attrs:{"href":"#","data-bs-toggle":"collapse","data-bs-target":"#collapseFour","aria-expanded":[_vm.showfaq === 4 ? 'true' : 'false'],"aria-controls":"collapseFour"},on:{"click":function($event){$event.preventDefault();return _vm.faqlist(4)}}},[_vm._v(" 서비스에서 제공하는 데이터는 어떤 출처에서 나오는 건가요? ")])])]),_c('div',{class:[
                  _vm.showfaq === 4
                    ? 'accordion-collapse show'
                    : 'accordion-collapse collapse',
                ],attrs:{"id":"collapseFour","aria-labelledby":"panelsStayOpen-headingFour"}},[_vm._m(4)])])])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-xl-6 col-lg-6 col-md-12 wow fadeInLeft faq-img-wraper"},[_c('div',{staticClass:"faq-img"},[_c('img',{attrs:{"src":require("@/assets/img/new/new-faq.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accordion-body"},[_c('p',{staticClass:"faq-txt"},[_vm._v(" 현재 모든 콘텐츠가 무료로 제공되고 있습니다. 하지만 추후 프리미엄 기능 및 특별한 훈련 프로그램에 대해서는 유료 구독이 필요할 수 있습니다. 'Plan' 페이지에서 요금에 대한 자세한 정보를 확인할 수 있습니다. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accordion-body"},[_c('p',{staticClass:"faq-txt"},[_vm._v(" 스포바이 서비스는 선수들의 레벨과 목표에 맞는 훈련 프로그램을 제공합니다. '자료 찾기'에서 원하는 프로그램을 찾아서 도전해보세요! ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accordion-body"},[_c('p',{staticClass:"faq-txt"},[_vm._v(" 훈련 제작 툴은 현재 개발 중에 있으며 추후 '내 훈련 만들기' 기능에서 목표를 설정하고 특별한 요구를 추가하여 자신만의 훈련 계획을 간편하게 작성할 수 있습니다. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accordion-body"},[_c('p',{staticClass:"faq-txt"},[_vm._v(" 우리의 데이터는 주요 축구 대회 및 전문가들의 분석을 기반으로 합니다. 우리의 팀은 정확하고 신뢰성 있는 데이터를 제공하기 위해 지속적으로 노력하고 있습니다. ")])])
}]

export { render, staticRenderFns }