<template>
  <div>
    <HeaderOne />
    <div class="product-detail">
      <Breadcrumb
        title="GRAND OPENING"
        sub-title="모든 자료를 무료로 즐겨보세요."
      />
      <div
        id="apork-videos-area"
        class="apork-videos-area mt-100 mb-100 wow fadeInUp"
      >
        <CoolLightBox
          :items="[{ src: product.youtubeUrl }]"
          :index="index"
          @close="index = null"
        />
        <div class="detail-wraper">
          <div class="detail-box">
            <!-- 사이드바 시작 -->
            <div class="box-nav-wraper">
              <h2 class="box-nav-title">강의 카테고리</h2>
              <div class="box-nav-line"></div>
              <ul class="box-nav-item">
                <li class="box-nav-li">
                  <a href="/product-list" class="box-nav-li-a">전체</a
                  ><span>></span>
                </li>
                <li class="box-nav-li">
                  <a href="/product-list-best" class="box-nav-li-a">BEST</a
                  ><span>></span>
                </li>
                <li class="box-nav-li">
                  <a href="/product-list-program" class="box-nav-li-a"
                    >프로그램</a
                  ><span>></span>
                </li>
                <li class="box-nav-li">
                  <a href="/product-list-education" class="box-nav-li-a"
                    >교육 강의</a
                  ><span>></span>
                </li>
                <li class="box-nav-li">
                  <a href="/product-list-pro" class="box-nav-li-a">프로 구단</a
                  ><span>></span>
                </li>
                <li class="box-nav-li">
                  <a href="/product-list-youth" class="box-nav-li-a">유소년</a
                  ><span>></span>
                </li>
              </ul>
            </div>

            <!-- 이미지 시작 -->
            <div class="box-img-wraper">
              <div class="box-img-bg" @click="index = 0">
                <img :src="product.image" class="box-img-title" />
              </div>
              <div class="box-img-sub-wraper">
                <img :src="product.image2" class="subimg" />
                <img :src="product.image3" class="subimg" />
              </div>
            </div>

            <!-- 내용 시작 -->
            <div class="box-info-wraper">
              <div class="side-line-01"></div>
              <div class="box-info-bg">
                <h3>
                  {{ product.title }}
                </h3>
                <p class="box-info-des">{{ product.description }}</p>
                <hr class="box-info-line-01" />
                <ul>
                  <li class="box-info-item">
                    <span class="info-cate">영상 길이</span
                    ><span class="info-cate-answer">{{ product.length }}</span>
                  </li>
                  <li class="box-info-item">
                    <span class="info-cate">조회 횟수</span
                    ><span class="info-cate-answer">{{ product.number }}</span>
                  </li>
                  <li class="box-info-item">
                    <span class="info-cate">이용 등급</span
                    ><span class="info-cate-answer">{{ product.rank }}</span>
                  </li>
                  <li class="box-info-item">
                    <span class="info-cate">훈련 강도</span
                    ><span class="info-cate-answer">{{
                      product.strength
                    }}</span>
                  </li>
                  <li class="box-info-item">
                    <span class="info-cate">참여 인원</span
                    ><span class="info-cate-answer">{{
                      product.participation
                    }}</span>
                  </li>
                  <li class="box-info-item">
                    <span class="info-cate">적정 연령</span
                    ><span class="info-cate-answer">{{ product.age }}</span>
                  </li>
                </ul>
                <hr class="box-info-line-02" />
                <div class="info-btn-wraper">
                  <a class="info-btn-01" :href="downloadUrl" download
                    >다운로드</a
                  >
                  <button class="info-btn-02" @click="copyLink">
                    공유하기
                  </button>
                </div>
              </div>
              <div class="side-line-02"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<!-- 상품번호 {{ product.id }} -->
<!-- 상품명 {{ product.title }} -->
<!-- 상품설명 {{ product.description }} -->
<!-- 상품이미지 {{ product.title }} -->

<script>
import HeaderOne from "../Header.vue";
import Footer from "../Footer.vue";
import Breadcrumb from "../Breadcrumb.vue";
import CoolLightBox from "vue-cool-lightbox";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";

export default {
  name: "DetailProduct",
  components: { HeaderOne, Footer, Breadcrumb, CoolLightBox },
  data() {
    return {
      video_title: "Watch Video",
      index: null,
    };
  },
  methods: {
    copyLink() {
      const link = window.document.location.href;
      window.navigator.clipboard.writeText(link).then(() => {
        alert("복사가 완료되었습니다");
      });
    },
    download(url) {
      window.open(window.location.origin + url, "_blank");
    },
  },
  computed: {
    product() {
      return this.$route.params.product;
    },
    downloadUrl() {
      return window.location.origin + this.product.downloadUrl;
    },
  },
};
</script>

<style>
@media screen and (min-width: 1280px) and (max-width: 1500px) {
  .box-info-bg h3 {
    font-size: 15px;
  }
}

.product-detail {
  height: calc(100vh - 237px);
}

.product-detail .apork-breadcrumb-area {
  padding: 50px 0;
}

.image-btn {
  cursor: pointer;
}

/* 메인 컨텐츠 시작 */
.detail-wraper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  /* border: 1px solid red; */
}
.detail-box {
  width: 80%;
  height: 90%;
  display: flex;
}

/* 카테고리 */
.box-nav-wraper {
  width: 14%;
  height: 100%;
  border: 2px solid #eeeeee;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.box-nav-title {
  font-size: 22px;
  font-weight: 700;
  margin-top: 30px;
  margin-bottom: 20px;
}
.box-nav-line {
  width: 70%;
  height: 2px;
  background-color: #002870;
  margin-bottom: 15px;
}
.box-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
}
.box-nav-item li {
  padding: 10px 0;
  font-size: 17px;
  font-weight: 500;
  width: 60%;
}
.box-nav-li {
  display: flex;
  justify-content: space-between;
}
.box-nav-li:hover {
  background-color: rgb(0, 40, 112, 0.1);
  color: #002870;
}
.box-nav-li-a {
  color: black;
}

/* 이미지 */
.box-img-wraper {
  flex: 1;
  margin-left: 30px;
}
.box-img-bg {
  width: 550px;
  height: 400px;
  background-image: url("@/assets/img/v-product/v-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: rgb(0, 0, 0, 0.5);
  position: relative;
  cursor: pointer;
}
.box-img-title {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.box-img-sub-wraper {
  display: flex;
}
.subimg {
  width: 265px;
  height: 170px;
  margin-top: 30px;
  border: 2px solid #eeeeee;
}
.subimg:first-child {
  margin-right: 20px;
}

/* 제품 내용 */
.box-info-wraper {
  flex: 1;
  display: flex;
  justify-content: space-around;
  margin-left: 40px;
}
.box-info-bg h3 {
  font-weight: 600;
  font-size: 28px;
}
.side-line-01,
.side-line-02 {
  width: 2px;
  height: 600px;
  background-color: #002870;
}
.box-info-bg {
  width: 90%;
}
.box-info-des {
  font-size: 18px;
  font-weight: 400;
  color: #969696;
  margin-top: 5px;
}
.box-info-line-01 {
  border: 1px solid #969696;
  margin-top: 15px;
  margin-bottom: 25px;
}
.box-info-line-02 {
  border: 1px solid #969696;
  margin-top: 30px;
}

.box-info-item {
  margin-bottom: 15px;
  margin-left: 20px;
  font-weight: 700;
}
.info-cate {
  font-size: 16px;
  font-weight: 700;
}
.info-cate-answer {
  font-size: 16px;
  font-weight: 500;
  margin-left: 50px;
  color: #747474;
}

.info-btn-wraper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}
.info-btn-01 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40%;
  height: 50px;
  border: 1px solid #2463eb;
  background-color: #2463eb;
  border-radius: 10px;
  color: white;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.info-btn-02 {
  width: 40%;
  height: 50px;
  border: 1px solid #eeeeee;
  background-color: #eeeeee;
  border-radius: 10px;
  color: black;
  font-size: 18px;
  font-weight: 500;
}

.info-btn-01:hover {
  border: 1px solid #1187cf;
  background-color: #1187cf;
}
.info-btn-02:hover {
  border: 1px solid #c2c2c2;
  background-color: #c2c2c2;
}
</style>
