<template>
  <div>
    <HeaderOne />
    <div class="newcontact-container">
      <Breadcrumb title="CONTACT US" />
      <div class="contactus-wraper">
        <div class="contactus-bg">
          <div class="location-wraper">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3160.144161648004!2d126.82179367642621!3d37.622296620927955!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x357c9b05a8d3f703%3A0x3393d3b226672dd1!2z7Iqk7Y-s67CU7J20!5e0!3m2!1sko!2skr!4v1701539423228!5m2!1sko!2skr"
              width="100%"
              height="75%"
              style="border: none"
              class="location"
            />
            <div class="location-txt-wraper">
              <p class="text-sm font-medium mb-1 contact-font location-txt-01"
                >📍 경기도 고양시 덕양구 지도로 57, 3층 417호</p
              >
              <p class="text-sm font-medium mb-1 contact-font">
                📞 031-2799-8265</p
              >
            </div>
          </div>
          <div class="contact-wraper">
            <form class="contact-bg">
              <div class="contact-input">
                <label class="text-sm font-medium mb-1 contact-font">이름</label
                ><span class="text-rose-500">*</span>
                <input
                  type="text"
                  id="username"
                  class="form-input py-2 w-full input-login-01"
                  required
                />
              </div>
              <div class="contact-input">
                <label class="text-sm font-medium mb-1 contact-font"
                  >이메일</label
                ><span class="text-rose-500">*</span>
                <input
                  type="text"
                  id="username"
                  class="form-input py-2 w-full input-login-01"
                  required
                />
              </div>
              <div class="contact-input">
                <label class="text-sm font-medium mb-1 contact-font"
                  >문의 내용</label
                ><span class="text-rose-500">*</span>
                <textarea
                  type="text"
                  id="username"
                  class="form-input py-2 w-full input-login-01 contact-txt"
                  required
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <button
                class="btn-sm w-full text-sm text-white bg-blue-600 hover:bg-blue-700 group py-3 mb-0 contact-btn"
              >
                문의 보내기
                <span
                  class="tracking-normal text-blue-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"
                  >-&gt;</span
                >
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderOne from "../Header.vue";
import Footer from "../Footer.vue";
import Breadcrumb from "../Breadcrumb.vue";

export default {
  /* eslint-disable */
  name: "Newcontact",
  components: { HeaderOne, Footer, Breadcrumb },
  data() {
    return {};
  },
};
</script>

<style>
.newcontact-container {
  height: 100%;
  background-color: rgb(0, 40, 112, 0.1);
}
.contactus-wraper {
  display: flex;
  justify-content: center;
}
.contactus-bg {
  width: 60%;
  height: 600px;
  display: flex;
  border: 1px solid white;
  border-radius: 15px;
  margin: 60px 0;
  background-color: white;
}
.location-wraper {
  width: 57%;
}
.contact-wraper {
  width: 30%;
  margin-left: 90px;
}
.contact-bg {
  margin-top: 30px;
}
.contact-font {
  font-size: 15px;
}
.location {
  margin-top: 30px;
  margin-left: 30px;
}
.location-txt-wraper {
  margin-top: 50px;
  margin-left: 30px;
  color: #969696;
}
.location-txt-01 {
    margin-bottom: 50px;
}
.contact-input {
  margin-bottom: 20px;
}
.contact-txt {
  height: 150px;
}
.contact-btn {
  border-radius: 10px;
}
</style>
