var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"apork-client-slider pt-110 pb-110",attrs:{"id":"apork-client-slider"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-10 col-lg-10 col-md-12 mx-auto"},[_c('div',{staticClass:"all-client-slider"},[_c('carousel',{attrs:{"autoplay":true,"items":4,"nav":false,"loop":true,"dots":false,"smartSpeed":1000,"responsive":{
                            0: {
                                items: 1
                            },
                            600: {
                                items: 2
                            },
                            1000: {
                                items: 4
                            }
                        },"responsive-base-element":"body"}},[_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"single-client"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/client/partner-01.png"),"alt":""}})])]),_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"single-client"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/client/partner-10.png"),"alt":""}})])]),_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"single-client"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/client/partner-03.png"),"alt":""}})])]),_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"single-client"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/client/partner-08.png"),"alt":""}})])]),_c('a',{attrs:{"href":"#"}},[_c('div',{staticClass:"single-client"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/client/partner-11.png"),"alt":""}})])])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }