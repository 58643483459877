<template>
  <div>
    <HeaderOne />
    <div class="product-detail">
      <Breadcrumb
        title="GRAND OPENING"
        sub-title="모든 자료를 무료로 즐겨보세요."
      />

      <div class="contents-box-wraper">
        <div class="contents-box">
          <!-- 사이드 바 시작 -->
          <div class="side-nav-wraper">
            <div class="side-nav-line"></div>
            <h3 class="side-nav-title">인기 카테고리</h3>
            <ul class="side-nav-item">
              <div class="side-nav-item-bg-01">
                <li class="nav-li-01">
                  <a href="/product-list" class="nav-li-01-a">전체</a
                  ><span>></span>
                </li>
              </div>
              <li class="nav-li">
                <a href="/product-list-best" class="nav-li-02-a">BEST</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-program" class="nav-li-03-a"
                  >프로그램</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-education" class="nav-li-04-a"
                  >교육 강의</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-pro" class="nav-li-05-a">프로 구단</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-youth" class="nav-li-06-a">유소년</a
                ><span>></span>
              </li>
            </ul>
          </div>

          <!-- 메인 컨텐츠 시작 -->
          <div class="main-con-wraper">
            <div class="main-con-bg">
              <product
                v-for="(product, index) in products"
                :key="`product${index}`"
                :product="product"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import HeaderOne from "../Header.vue";
import Footer from "../Footer.vue";
import Breadcrumb from "../Breadcrumb.vue";
import Product from "../Product.vue";

export default {
  name: "ProductList",
  components: { HeaderOne, Footer, Breadcrumb, Product },
  data() {
    return {
      products: [
        {
          id: 1,
          title: "Endurance into a Game",
          description: "피지컬 강화를 위한 4vs4 레이스 게임",
          image: require("/src/assets/img/new/insta-12.png"),
          image2: require("/src/assets/img/sub-img/insta-12-01.png"),
          image3: require("/src/assets/img/sub-img/insta-12-02.png"),
          youtubeUrl: "https://youtu.be/5nneJtB7NMM",
          downloadUrl: "/video/Endurance into a Game.mp4",
          length: "00:29",
          number: "252",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 8명",
          age: "14 ~",
        },
        {
          id: 2,
          title: "[KFA 아카데미] 드리블&페인팅",
          description:
            "KFA 전임 지도자 송승준 코치가 알려주는 유소년 드리블 훈련",
          image: require("/src/assets/img/new/youtube-12.png"),
          image2: require("/src/assets/img/sub-img/youtube-12-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-12-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=1zu_vRD4Z3A&t=26s",
          downloadUrl: "/video/[KFA 아카데미] 드리블&페인팅.mp4",
          length: "08:52",
          number: "376",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 10명",
          age: "10 ~ 15",
        },
        {
          id: 3,
          title: "[싸커최] 킥 & 컨트롤",
          description: "현직 유소년 감독이 알려주는 축구의 기본기",
          image: require("/src/assets/img/new/youtube-16.png"),
          image2: require("/src/assets/img/sub-img/youtube-16-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-16-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=9SC8R-uHfo4&t=624s",
          downloadUrl: "/video/[싸커최] 킥 & 컨트롤.mp4",
          length: "12:31",
          number: "35",
          rank: "무료",
          strength: "중",
          participation: "1 ~ 2명",
          age: "11 ~ 20",
        },
        {
          id: 4,
          title: "[Barcelona] 23-24 시즌 첫 오픈 트레이닝",
          description: "스페인 라리가 명문팀 바르셀로나의 시즌 첫 훈련",
          image: require("/src/assets/img/new/youtube-09.png"),
          image2: require("/src/assets/img/sub-img/youtube-09-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-09-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=AYmGGidxncI&t=37s",
          downloadUrl: "/video/[Barcelona] 23-24 시즌 첫 오픈 트레이닝.mp4",
          length: "04:12",
          number: "198",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 5,
          title: "[30분 코스] 패스와 컨트롤 훈련",
          description: "패스와 컨트롤 간단한 훈련 10가지",
          image: require("/src/assets/img/new/youtube-03.png"),
          image2: require("/src/assets/img/sub-img/youtube-03-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-03-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=F7e0QpvF9fA&t=7s",
          downloadUrl: "/video/[30분 코스] 패스와 컨트롤 훈련.mp4",
          length: "06:36",
          number: "204",
          rank: "무료",
          strength: "중",
          participation: "1명",
          age: "10 ~ 20",
        },
        {
          id: 6,
          title: "[FC Seoul] 2023시즌 울산전 대비 훈련",
          description: "K리그 울산과의 홈경기 대비 훈련",
          image: require("/src/assets/img/new/youtube-22.png"),
          image2: require("/src/assets/img/sub-img/youtube-22-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-22-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=Km9Q-2e6HKU&t=453s",
          downloadUrl: "/video/[FC Seoul] 2023시즌 울산전 대비 훈련.mp4",
          length: "11:07",
          number: "96",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 7,
          title: "[KFA 골든에이지] 골키퍼 빌드업",
          description: "골키퍼가 빌드업을 하기 위해서 가장 중요한 요소",
          image: require("/src/assets/img/new/youtube-23.png"),
          image2: require("/src/assets/img/sub-img/youtube-23-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-23-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=CbJMtXY7KsA&t=35s",
          downloadUrl: "/video/[KFA 골든에이지] 골키퍼 빌드업.mp4",
          length: "07:11",
          number: "773",
          rank: "무료",
          strength: "중하",
          participation: "1 ~ 3명",
          age: "11 ~ 16",
        },
        {
          id: 8,
          title: "초등학생 기본 볼 마스터리 훈련법",
          description:
            "초등 저학년 축구부 혹은 초보자 필수! 11가지 기본 감각 훈련",
          image: require("/src/assets/img/new/youtube-01.png"),
          image2: require("/src/assets/img/sub-img/youtube-01-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-01-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=s155EZW3qzs&t=7s",
          downloadUrl: "/video/초등학생 기본 볼 마스터리 훈련법.mp4",
          length: "03:06",
          number: "355",
          rank: "무료",
          strength: "중하",
          participation: "1명",
          age: "8 ~ 13",
        },
        {
          id: 9,
          title: "6인 슈팅 프로그램",
          description: "슈팅 + 패싱 향상 6인 프로그램",
          image: require("/src/assets/img/new/insta-18.png"),
          image2: require("/src/assets/img/sub-img/insta-18-01.png"),
          image3: require("/src/assets/img/sub-img/insta-18-02.png"),
          youtubeUrl: "https://youtu.be/9Y8GhY7uZk4",
          downloadUrl: "/video/6인 슈팅 프로그램.mp4",
          length: "00:08",
          number: "139",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 6명",
          age: "13 ~ 16",
        },
        {
          id: 10,
          title: "[KFA] 인지능력 향상 2편",
          description: "오성환 코치가 알려주는 인지능력 향상 2편",
          image: require("/src/assets/img/new/youtube-27.png"),
          image2: require("/src/assets/img/sub-img/youtube-27-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-27-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=XaNB9hqx6wk&t=98s",
          downloadUrl: "/video/[KFA] 인지능력 향상 2편.mp4",
          length: "06:29",
          number: "552",
          rank: "무료",
          strength: "중",
          participation: "1 ~ 2명",
          age: "10 ~ 13",
        },
        {
          id: 11,
          title: "혼자서 하는 드리블링",
          description: "풋워크와 드리블을 결합한 1인 훈련 프로그램",
          image: require("/src/assets/img/new/insta-14.png"),
          image2: require("/src/assets/img/sub-img/insta-14-01.png"),
          image3: require("/src/assets/img/sub-img/insta-14-02.png"),
          youtubeUrl: "https://youtu.be/PeXuqbZZu_0",
          downloadUrl: "/video/혼자서 하는 드리블링.mp4",
          length: "00:17",
          number: "241",
          rank: "무료",
          strength: "중상",
          participation: "1명",
          age: "13 ~ 20",
        },
        {
          id: 12,
          title: "[싸커최] 축구 기본기",
          description: "현직 유소년 감독이 알려주는 축구의 기본기",
          image: require("/src/assets/img/new/youtube-15.png"),
          image2: require("/src/assets/img/sub-img/youtube-15-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-15-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=u04FGeu3QCk&t=11s",
          downloadUrl: "/video/[싸커최] 축구 기본기.mp4",
          length: "06:41",
          number: "344",
          rank: "무료",
          strength: "중",
          participation: "1 ~ 2명",
          age: "8 ~ 13",
        },
        {
          id: 13,
          title: "[드리블+슈팅] 6인 1조",
          description:
            "6명씩 한 그룹으로 드리블과 슈팅까지 한번에 연결하는 훈련!",
          image: require("/src/assets/img/new/insta-02.png"),
          image2: require("/src/assets/img/sub-img/insta-02-01.png"),
          image3: require("/src/assets/img/sub-img/insta-02-02.png"),
          youtubeUrl: "https://youtu.be/mexd9Y_5Aos",
          downloadUrl: "/video/[드리블+슈팅] 6인 1조.mp4",
          length: "00:08",
          number: "131",
          rank: "무료",
          strength: "중하",
          participation: "그룹 - 6명",
          age: "10 ~ 19",
        },
        {
          id: 14,
          title: "소프트한 터치를 위한 스킬",
          description: "마커 3개만 있으면 되는 1인 드리블 훈련",
          image: require("/src/assets/img/new/insta-15.png"),
          image2: require("/src/assets/img/sub-img/insta-15-01.png"),
          image3: require("/src/assets/img/sub-img/insta-15-02.png"),
          youtubeUrl: "https://youtu.be/gtI9CiEknD0",
          downloadUrl: "/video/소프트한 터치를 위한 스킬.mp4",
          length: "00:21",
          number: "221",
          rank: "무료",
          strength: "중하",
          participation: "1명",
          age: "9 ~ 16",
        },
        {
          id: 15,
          title: "Finishing Circuit",
          description: "골 결정력 강화를 위한 서킷",
          image: require("/src/assets/img/new/insta-09.png"),
          image2: require("/src/assets/img/sub-img/insta-09-01.png"),
          image3: require("/src/assets/img/sub-img/insta-09-02.png"),
          youtubeUrl: "https://youtu.be/gsaoQyI4QsU",
          downloadUrl: "/video/Finishing Circuit.mp4",
          length: "00:37",
          number: "587",
          rank: "무료",
          strength: "중상",
          participation: "그룹 - 9명",
          age: "13 ~",
        },
        {
          id: 16,
          title: "초보자 필수 훈련",
          description: "내가 축구 초보라면? 반드시 이 영상을 보세요!",
          image: require("/src/assets/img/new/youtube-02.png"),
          image2: require("/src/assets/img/sub-img/youtube-02-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-02-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=CHhpEGuPsqk&t=3s",
          downloadUrl: "/video/초보자 필수 훈련.mp4",
          length: "05:47",
          number: "366",
          rank: "무료",
          strength: "중",
          participation: "1명",
          age: "10 ~ 16",
        },
        {
          id: 17,
          title: "[세뇰킴] 사비 알론소처럼 롱킥 차는법",
          description: "6명씩 한 그룹을 만들어서 2대1 패스 훈련을 하는 방법은?",
          image: require("/src/assets/img/new/youtube-06.png"),
          image2: require("/src/assets/img/sub-img/youtube-06-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-06-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=H83K7Zyu0P4",
          downloadUrl: "/video/[세뇰킴] 사비 알론소처럼 롱킥 차는법.mp4",
          length: "02:01",
          number: "409",
          rank: "무료",
          strength: "중하",
          participation: "1명",
          age: "16 ~ 20",
        },
        {
          id: 18,
          title: "[기본기] 인사이드 패스 강좌",
          description: "축구에서 가장 중요한 기본기-패스",
          image: require("/src/assets/img/new/youtube-04.png"),
          image2: require("/src/assets/img/sub-img/youtube-04-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-04-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=cR9Dspq-Kjo&t=311s",
          downloadUrl: "/video/[기본기] 인사이드 패스 강좌.mp4",
          length: "06:25",
          number: "109",
          rank: "무료",
          strength: "하",
          participation: "1 ~ 2명",
          age: "8 ~ 20",
        },
        {
          id: 19,
          title: "혼자서도 가능한 종합 훈련",
          description: "이 훈련은 모든 종류의 움직임과 기술을 포함합니다.",
          image: require("/src/assets/img/new/insta-03.png"),
          image2: require("/src/assets/img/sub-img/insta-03-01.png"),
          image3: require("/src/assets/img/sub-img/insta-03-02.png"),
          youtubeUrl: "https://youtu.be/-0y9TSvfdV8",
          downloadUrl: "/video/혼자서도 가능한 종합 훈련.mp4",
          length: "00:24",
          number: "212",
          rank: "무료",
          strength: "중상",
          participation: "1명",
          age: "13 ~ 19",
        },
        {
          id: 20,
          title: "[KFA] 인지능력 향상 3편",
          description: "오성환 코치가 알려주는 인지능력 향상 3편",
          image: require("/src/assets/img/new/youtube-28.png"),
          image2: require("/src/assets/img/sub-img/youtube-28-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-28-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=K5j7m9pc4tM&t=38s",
          downloadUrl: "/video/[KFA] 인지능력 향상 3편.mp4",
          length: "05:50",
          number: "431",
          rank: "무료",
          strength: "중상",
          participation: "그룹 - 7명",
          age: "10 ~ 16",
        },
        {
          id: 21,
          title: "1 v 1 Games",
          description: "스프린트, 헤딩 1vs1 레이스",
          image: require("/src/assets/img/new/insta-10.png"),
          image2: require("/src/assets/img/sub-img/insta-10-01.png"),
          image3: require("/src/assets/img/sub-img/insta-10-02.png"),
          youtubeUrl: "https://youtu.be/IuOPkyAPlLg",
          downloadUrl: "/video/1 v 1 Games.mp4",
          length: "00:30",
          number: "608",
          rank: "무료",
          strength: "중상",
          participation: "그룹 - 4명",
          age: "12 ~ 20",
        },
        {
          id: 22,
          title: "[Arsenal] 23-24 트레이닝 세션",
          description: "EPL 소속 아스날의 피지컬 트레이닝",
          image: require("/src/assets/img/new/youtube-21.png"),
          image2: require("/src/assets/img/sub-img/youtube-21-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-21-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=CwgRMN6ipNw&t=186s",
          downloadUrl: "/video/[Arsenal] 23-24 트레이닝 세션.mp4",
          length: "07:29",
          number: "152",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 23,
          title: "[서벤사커] 중거리 슈팅",
          description: "무회전 중거리 슈팅을 하는 방법",
          image: require("/src/assets/img/new/youtube-13.png"),
          image2: require("/src/assets/img/sub-img/youtube-13-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-13-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=94glZJjPexo",
          downloadUrl: "/video/[서벤사커] 중거리 슈팅.mp4",
          length: "06:18",
          number: "442",
          rank: "무료",
          strength: "중하",
          participation: "1 ~ 2명",
          age: "17 ~ 20",
        },
        {
          id: 24,
          title: "슬라럼 드리블 슈팅 훈련",
          description: "드리블에서 나오는 슈팅을 향상시키는 훌륭한 드릴 세션!",
          image: require("/src/assets/img/new/insta-04.png"),
          image2: require("/src/assets/img/sub-img/insta-04-01.png"),
          image3: require("/src/assets/img/sub-img/insta-04-02.png"),
          youtubeUrl: "https://youtu.be/6UDLGHIDOD8",
          downloadUrl: "/video/슬라럼 드리블 슈팅 훈련.mp4",
          length: "00:40",
          number: "97",
          rank: "무료",
          strength: "중하",
          participation: "1명",
          age: "11 ~ 20",
        },
        {
          id: 25,
          title: "[KFA] 인지능력 향상 4편",
          description: "오성환 코치가 알려주는 인지능력 향상 4편",
          image: require("/src/assets/img/new/youtube-11.png"),
          image2: require("/src/assets/img/sub-img/youtube-11-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-11-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=yxDZ7KTWND4",
          downloadUrl: "/video/[KFA] 인지능력 향상 4편.mp4",
          length: "05:54",
          number: "610",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 7명",
          age: "10 ~ 16",
        },
        {
          id: 26,
          title: "[여자 축구] 피지컬 강화",
          description: "여자 축구 최강팀에서 보여주는 피지컬 강화 훈련",
          image: require("/src/assets/img/new/insta-06.png"),
          image2: require("/src/assets/img/sub-img/insta-06-01.png"),
          image3: require("/src/assets/img/sub-img/insta-06-02.png"),
          youtubeUrl: "https://youtu.be/7N7gsC90csk",
          downloadUrl: "/video/[여자 축구] 피지컬 강화.mp4",
          length: "00:12",
          number: "177",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 5명",
          age: "16 ~",
        },
        {
          id: 27,
          title: "[조세민의 레슨] 드리블 강좌",
          description: "100% 상대를 이길 수 있는 드리블 기술",
          image: require("/src/assets/img/new/youtube-08.png"),
          image2: require("/src/assets/img/sub-img/youtube-08-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-08-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=VOi5ZqWxpC4&t=3s",
          downloadUrl: "/video/[조세민의 레슨] 드리블 강좌.mp4",
          length: "04:05",
          number: "262",
          rank: "무료",
          strength: "중하",
          participation: "1 ~ 2명",
          age: "10 ~ 16",
        },
        {
          id: 28,
          title: "[Liverpool] 알리송을 위한 첫 번째 세션",
          description: "EPL의 전통적인 축구팀 리버풀의 수문장 알리송의 훈련",
          image: require("/src/assets/img/new/youtube-10.png"),
          image2: require("/src/assets/img/sub-img/youtube-10-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-10-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=VMbhF-whpX4",
          downloadUrl: "/video/[Liverpool] 알리송을 위한 첫 번째 세션.mp4",
          length: "18:29",
          number: "225",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 29,
          title: "2인1조 드리블 레이스",
          description: "드리블과 슈팅을 섞은 2인 1조 레이스 게임",
          image: require("/src/assets/img/new/insta-07.png"),
          image2: require("/src/assets/img/sub-img/insta-07-01.png"),
          image3: require("/src/assets/img/sub-img/insta-07-02.png"),
          youtubeUrl: "https://youtu.be/wiKXLiA1q0c",
          downloadUrl: "/video/2인1조 드리블 레이스.mp4",
          length: "00:12",
          number: "374",
          rank: "무료",
          strength: "중",
          participation: "2명",
          age: "10 ~ 20",
        },
        {
          id: 30,
          title: "[Tottenham] 주말 경기 대비 트레이닝",
          description: "손흥민 선수가 있는 토트넘의 웨스트햄전 대비 트레이닝",
          image: require("/src/assets/img/new/youtube-20.png"),
          image2: require("/src/assets/img/sub-img/youtube-20-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-20-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=J1H-vRjYwRk",
          downloadUrl: "/video/[Tottenham] 주말 경기 대비 트레이닝.mp4",
          length: "03:52",
          number: "578",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 31,
          title: "프로 19년차가 알려주는 롱킥",
          description: "롱킥은 힘으로만 차는 게 아니라 임팩트가 중요합니다.",
          image: require("/src/assets/img/new/youtube-05.png"),
          image2: require("/src/assets/img/sub-img/youtube-05-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-05-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=R5JNBLYni70&t=87s",
          downloadUrl: "/video/프로 19년차가 알려주는 롱킥.mp4",
          length: "12:09",
          number: "52",
          rank: "무료",
          strength: "중하",
          participation: "1 ~ 2명",
          age: "17 ~",
        },
        {
          id: 32,
          title: "focuses on teamwork",
          description: "4vs4 SSG 프로그램",
          image: require("/src/assets/img/new/insta-08.png"),
          image2: require("/src/assets/img/sub-img/insta-08-01.png"),
          image3: require("/src/assets/img/sub-img/insta-08-02.png"),
          youtubeUrl: "https://youtu.be/oMEUng7aetw",
          downloadUrl: "/video/focuses on teamwork.mp4",
          length: "00:41",
          number: "439",
          rank: "무료",
          strength: "중상",
          participation: "그룹 - 4명",
          age: "13 ~ 20",
        },
        {
          id: 33,
          title: "[PSG] 23-24 트레이닝 세션",
          description: "리그앙 소속 파리 생제르망의 트레이닝",
          image: require("/src/assets/img/new/youtube-19.png"),
          image2: require("/src/assets/img/sub-img/youtube-19-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-19-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=1umHFX56l8c",
          downloadUrl: "/video/[PSG] 23-24 트레이닝 세션.mp4",
          length: "03:04",
          number: "527",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 34,
          title: "[미하엘 뮐러] 다양한 1vs1 훈련",
          description: "U11-U12 대상 선수들을 위한 1vs1 훈련",
          image: require("/src/assets/img/new/youtube-25.png"),
          image2: require("/src/assets/img/sub-img/youtube-25-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-25-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=0bA6d0m-6BY&t=302s",
          downloadUrl: "/video/[미하엘 뮐러] 다양한 1vs1 훈련.mp4",
          length: "06:01",
          number: "352",
          rank: "무료",
          strength: "중",
          participation: "2명",
          age: "11 ~ 12",
        },
        {
          id: 35,
          title: "[세뇰킴] 골대 앞에서 정확하게 슈팅하는 법",
          description: "6명씩 한 그룹을 만들어서 2대1 패스 훈련을 하는 방법은?",
          image: require("/src/assets/img/new/youtube-07.png"),
          image2: require("/src/assets/img/sub-img/youtube-07-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-07-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=y1C07lp3I38&t=31s",
          downloadUrl: "/video/[세뇰킴] 골대 앞에서 정확하게 슈팅하는 법.mp4",
          length: "02:33",
          number: "102",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 6명",
          age: "13 ~ 20",
        },
        {
          id: 36,
          title: "Passing Combinations",
          description: "패싱을 향상시키는 스핀 패싱 프로그램",
          image: require("/src/assets/img/new/insta-11.png"),
          image2: require("/src/assets/img/sub-img/insta-11-01.png"),
          image3: require("/src/assets/img/sub-img/insta-11-02.png"),
          youtubeUrl: "https://youtu.be/SQKSkgXkWI4",
          downloadUrl: "/video/Passing Combinations.mp4",
          length: "00:35",
          number: "422",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 5명",
          age: "13 ~ 20",
        },
        {
          id: 37,
          title: "풋워크 + 스텝",
          description: "측면 점프와 멀리뛰기를 결합한 코디네이션",
          image: require("/src/assets/img/new/insta-13.png"),
          image2: require("/src/assets/img/sub-img/insta-13-01.png"),
          image3: require("/src/assets/img/sub-img/insta-13-02.png"),
          youtubeUrl: "https://youtu.be/dIWluMwnsYU",
          downloadUrl: "/video/풋워크 + 스텝.mp4",
          length: "00:15",
          number: "295",
          rank: "무료",
          strength: "중",
          participation: "1명",
          age: "13 ~ 16",
        },
        {
          id: 38,
          title: "[레시피] 기본기 훈련",
          description: "축구선수들 모두가 하고 있는 기본기 강좌",
          image: require("/src/assets/img/new/youtube-14.png"),
          image2: require("/src/assets/img/sub-img/youtube-14-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-14-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=L4AmNlStkHE&t=262s",
          downloadUrl: "/video/[레시피] 기본기 훈련.mp4",
          length: "06:42",
          number: "370",
          rank: "무료",
          strength: "중하",
          participation: "2명",
          age: "10 ~ 20",
        },
        {
          id: 39,
          title: "1vs1 드릴 프로그램",
          description: "좁은 공간을 활용한 1vs1 프로그램",
          image: require("/src/assets/img/new/insta-16.png"),
          image2: require("/src/assets/img/sub-img/insta-16-01.png"),
          image3: require("/src/assets/img/sub-img/insta-16-02.png"),
          youtubeUrl: "https://youtu.be/tfkltm6tKPs",
          downloadUrl: "/video/1vs1 드릴 프로그램.mp4",
          length: "00:09",
          number: "122",
          rank: "무료",
          strength: "중",
          participation: "2명",
          age: "10 ~ 16",
        },
        {
          id: 40,
          title: "[Real Madrid] 오픈 트레이닝",
          description: "챔스 DNA, 세계 최고의 클럽의 오픈 트레이닝 영상",
          image: require("/src/assets/img/new/youtube-17.png"),
          image2: require("/src/assets/img/sub-img/youtube-17-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-17-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=GF6j-tYCDRg",
          downloadUrl: "/video/[Real Madrid] 오픈 트레이닝.mp4",
          length: "03:05",
          number: "401",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 41,
          title: "[이호욱 강사] 영양학 특강",
          description: "유소년 선수들을 위한 영양학 특강",
          image: require("/src/assets/img/new/youtube-24.png"),
          image2: require("/src/assets/img/sub-img/youtube-24-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-24-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=A6Ga6g0oM60&t=183s",
          downloadUrl: "/video/[이호욱 강사] 영양학 특강.mp4",
          length: "12:48",
          number: "429",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "8 ~ 19",
        },
        {
          id: 42,
          title: "8명이서 하는 패싱 드릴",
          description: "움직임과 리시빙을 향상하는 순환 프로그램",
          image: require("/src/assets/img/new/insta-17.png"),
          image2: require("/src/assets/img/sub-img/insta-17-01.png"),
          image3: require("/src/assets/img/sub-img/insta-17-02.png"),
          youtubeUrl: "https://youtu.be/hSPpK0luutY",
          downloadUrl: "/video/8명이서 하는 패싱 드릴.mp4",
          length: "00:07",
          number: "173",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 8명",
          age: "12 ~ 20",
        },
        {
          id: 43,
          title: "[Manchester United] 23-24 프리시즌",
          description: "EPL 소속 맨체스터 유나이티드의 프리시즌 트레이닝",
          image: require("/src/assets/img/new/youtube-18.png"),
          image2: require("/src/assets/img/sub-img/youtube-18-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-18-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=NJM8brrlHo0&t=281s",
          downloadUrl: "/video/[Manchester United] 23-24 프리시즌.mp4",
          length: "09:52",
          number: "294",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "없음",
        },
        {
          id: 44,
          title: "[KFA] 인지능력 향상 1편",
          description: "오성환 코치가 알려주는 인지능력 향상 1편",
          image: require("/src/assets/img/new/youtube-26.png"),
          image2: require("/src/assets/img/sub-img/youtube-26-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-26-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=OMKbXiWOZfA&t=124s",
          downloadUrl: "/video/[KFA] 인지능력 향상 1편.mp4",
          length: "05:56",
          number: "582",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "8 ~ 16",
        },
      ],
    };
  },
};
</script>

<style>
.product-detail {
  height: 100%;
}
.contents-box-wraper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.contents-box {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* 사이드 바 */
.side-nav-wraper {
  margin-top: 40px;
  width: 11.5%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.side-nav-line {
  width: 100%;
  height: 5px;
  background-color: #002870;
  margin-bottom: 15px;
}
.side-nav-title {
  font-size: 25px;
  font-weight: 700;
}
.side-nav-item-bg-01 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(0, 40, 112, 0.1);
}
.side-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 100%;
}
.side-nav-item li {
  padding: 10px 0;
  font-size: 17px;
  font-weight: 500;
  width: 80%;
}

.nav-li {
  display: flex;
  justify-content: space-between;
}
.nav-li-01 {
  color: #002870;
  display: flex;
  justify-content: space-between;
}
.nav-li-01-a {
  color: #002870;
}
.nav-li-02-a,
.nav-li-03-a,
.nav-li-04-a,
.nav-li-05-a,
.nav-li-06-a {
  color: black;
}

/* 메인 콘텐츠 */
.main-con-wraper {
  margin-top: 40px;
  width: 80%;
  height: 100%;
}
.main-con-bg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* 콘텐츠 */
</style>
