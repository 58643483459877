<template>
  <div>
    <!-- header area start -->
    <HeaderOne />
    <!-- header area end -->
    <!-- main area start -->
    <main>
      <!-- breadcrumb area start -->
      <Breadcrumb title="plan" />
      <!-- breadcrumb area end -->
      <div
        id="apork-pricing-table-area"
        class="apork-pricing-table-area mt-110 mb-110"
      >
        <div class="container">
          <div class="price-tab">
            <div class="price-menu-box text-center">
              <ul
                class="price-nav mb-40 nav flex-column nav-pills"
                id="myTab"
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    @click.prevent="selectpricing('tabset')"
                    class="nav-link"
                    :class="[selectedTab === 'tabset' ? 'active' : '']"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="apork-month-pack"
                    aria-selected="true"
                    >Monthly</a
                  >
                </li>
                <li class="nav-item">
                  <a
                    @click.prevent="selectpricing('yearly')"
                    class="nav-link"
                    :class="[selectedTab === 'yearly' ? 'active' : '']"
                    data-toggle="pill"
                    role="tab"
                    aria-controls="apork-yearly-pack"
                    aria-selected="false"
                    >Yearly</a
                  >
                </li>
              </ul>
            </div>

            <div class="tab-content" id="myTabContent">
              <div
                :class="[selectedTab === 'tabset' ? 'active show' : '']"
                class="tab-pane fade"
                role="tabpanel"
                aria-labelledby="apork-month-pack-tab"
              >
                <div class="row">
                  <div class="col-xl-4 col-lg-4 wow fadeInLeft">
                    <div class="single-price-area text-center mb-30">
                      <div class="badge-popular">
                        <span class="popular">POPULAR</span>
                      </div>
                      <div class="single-price-bg"></div>
                      <div class="price-header">
                        <h4>Primary</h4>
                        <h2>₩0<span class="sep">/</span><span>mo</span></h2>
                      </div>
                      <div class="price-item-list">
                        <ul>
                          <li>일반 자료 열람</li>
                          <li>100GB 데이터 다운</li>
                          <li>트레이닝 제작 툴 사용 가능</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 wow fadeInUp">
                    <div class="single-price-area active text-center mb-30">
                      <div class="badge-popular">
                        <span class="popular">POPULAR</span>
                      </div>
                      <div class="single-price-bg"></div>
                      <div class="price-header">
                        <h4>popular</h4>
                        <h2>₩6,400<span class="sep">/</span><span>mo</span></h2>
                      </div>
                      <div class="price-item-list">
                        <ul>
                          <li>프리미엄 자료 열람</li>
                          <li>데이터 무제한 다운로드</li>
                          <li>트레이닝 제작 툴의 고급 기능</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 wow fadeInRight">
                    <div class="single-price-area text-center mb-30">
                      <div class="badge-popular">
                        <span class="popular">POPULAR</span>
                      </div>
                      <div class="single-price-bg"></div>
                      <div class="price-header">
                        <h4>standard</h4>
                        <h2>₩8,900<span class="sep">/</span><span>mo</span></h2>
                      </div>
                      <div class="price-item-list">
                        <ul>
                          <li>프리미엄 자료 열람</li>
                          <li>데이터 무제한 다운로드</li>
                          <li>트레이닝 제작 툴의 모든 기능</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                :class="[selectedTab === 'yearly' ? 'active show' : '']"
                class="tab-pane fade"
                role="tabpanel"
                aria-labelledby="apork-yearly-pack-tab"
              >
                <div class="row">
                  <div class="col-xl-4 col-lg-4 wow fadeInLeft">
                    <div class="single-price-area text-center mb-30">
                      <div class="single-price-bg"></div>
                      <div class="price-header">
                        <h4>Primary</h4>
                        <h2>₩0<span class="sep">/</span><span>yr</span></h2>
                      </div>
                      <div class="price-item-list">
                        <ul>
                          <li>일반 자료 열람</li>
                          <li>100GB 데이터 다운</li>
                          <li>트레이닝 제작 툴 사용 가능</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 wow fadeInUp">
                    <div class="single-price-area active text-center mb-30">
                      <div class="badge-popular">
                        <span class="popular">POPULAR</span>
                      </div>
                      <div class="single-price-bg"></div>
                      <div class="price-header">
                        <h4>popular</h4>
                        <h2>
                          ₩58,000<span class="sep">/</span><span>yr</span>
                        </h2>
                      </div>
                      <div class="price-item-list">
                        <ul>
                          <li>프리미엄 자료 열람</li>
                          <li>데이터 무제한 다운로드</li>
                          <li>트레이닝 제작 툴의 모든 기능</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-4 wow fadeInRight">
                    <div class="single-price-area text-center mb-30">
                      <div class="single-price-bg"></div>
                      <div class="price-header">
                        <h4>standard</h4>
                        <h2>
                          ₩69,000<span class="sep">/</span><span>yr</span>
                        </h2>
                      </div>
                      <div class="price-item-list">
                        <ul>
                          <li>프리미엄 자료 열람</li>
                          <li>데이터 무제한 다운로드</li>
                          <li>트레이닝 제작 툴의 모든 기능</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- footer area start -->
      <FooterTwo />
      <!-- footer area end -->
    </main>
    <!-- main area end -->

    <!-- scrolltop button -->
    <a class="material-scrolltop" href="#"></a>
  </div>
</template>

<script>
import HeaderOne from "../Header.vue";
import Breadcrumb from "../Breadcrumb.vue";
import FooterTwo from "../Footer.vue";
export default {
  name: "PlanOne",
  components: { HeaderOne, Breadcrumb, FooterTwo },
  props: {
    psection_title: {
      type: String,
    },
    psection_para: {
      type: String,
    },
  },
  data() {
    return {
      selectedTab: "tabset",
      switchPlan: true,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    selectpricing(name) {
      this.selectedTab = name;
    },
    topToBottom() {
      const result = document.querySelector(".material-scrolltop");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("reveal");
      } else {
        result.classList.remove("reveal");
      }
    },
  },
};
</script>

<style></style>
