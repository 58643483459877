<template>
  <div id="apork-breadcrumb-area" class="apork-breadcrumb-area text-center">
    <div class="breadcrumb-overlay"></div>
    <div class="container position-relative">
      <div class="row">
        <div class="col-md-12">
          <h2 class="banner_title">{{ title }}</h2>
          <ul class="breadcrumb justify-content-center">
            <li class="breadcrumb-item active">{{ subTitle }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BreadcrumbOne",
  props: {
    title: {
      type: String,
    },
    subTitle: {
      type: String,
    },
  },
};
</script>

<style></style>
