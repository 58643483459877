<template>
  <footer id="apork-footer-area" class="apork-footer-area footer-b">
    <div class="container">
      <div class="row">
        <div class="col-xl-12 col-lg-12">
          <div class="footer-copyright text-center">
            <ul class="footer-social">
              <li>
                <a href="#"><i class="fa fa-facebook"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-twitter"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-instagram"></i></a>
              </li>
              <li>
                <a href="#"><i class="fa fa-vk"></i></a>
              </li>
        </ul>
            <p v-html="copy_r"></p>
          </div>
        </div>
      </div>
    </div>
    
  </footer>
</template>

<script>
import logo from "@/assets/img/logo-2.png";
export default {
  name: "FooterOne",
  data() {
    return {
      image: logo,
      title1: "Popular",
      title2: "Latest News",
      title3: "Gallery Image",
      about_para:
        "But I must explain to you how all this mistaken idea of denouncing asure and praising pain was born and I will give you a",
      copy_r: '&copy; 2023 All Right Reserved by <a href="#">SPOBY</a>',
    };
  },
};
</script>

<style>
</style>
