<template>
  <div>
    <!-- header area start -->
    <HeaderOne />
    <!-- header area end -->
    <!-- main area start -->
    <main>
      <!-- hero area start -->
      <HeroOne />
      <!-- hero area end -->
      <!-- about area start -->
      <AboutOne />
      <!-- about area end -->
      <!-- counter area start -->
      <!-- <CounterOne /> -->
      <!-- counter area end -->
      <!-- features area start -->
      <!-- <FeaturesOne
        :grid_items="itemsGrid"
        section_title="Service Features"
        section_para="우리 스포바이 서비스는 전 세계 축구 애호가들과 전문가들이 함께 모여 소중한 경험과 알고 있는 지식을 공유하고, 서로의 성장을 도울 수 있는 플랫폼입니다."
      /> -->
      <!-- features area end -->
      <!-- videos area start -->
      <!-- <VideoOne /> -->
      <!-- videos area end -->
      <!-- screenshot area start -->
      <!-- <ScreenshotOne shsection_title="App Screenshot"
                shsection_para="Lorem ipsum dolor sit amt, consectet adop adipisicing elit, sed do eiusmod more incididunt ugt labore." /> -->
      <!-- screenshot area end -->
      <!-- call to action area start -->
      <!-- <CtaOne /> -->
      <!-- call to action area end -->
      <!-- pricing table area start -->
      <!-- <PricingOne psection_title="Pricing Plan"
                psection_para="" /> -->
      <!-- pricing table area end -->
      <!-- faq area start -->
      <!-- <FaqOne /> -->
      <!-- faq area end -->
      <!-- testimonial area start -->
      <TestimonialOne />
      <!-- testimonial area end -->
      <!-- latest blog area start -->
      <!-- <BlogOne /> -->
      <!-- latest blog area end -->
      <!-- subscribe area start -->
      <SubscribeOne />
      <!-- subscribe area end -->
      <!-- client slider area start -->
      <ClientOne />
      <!-- client slider area end -->
      <!-- footer area start -->
      <FooterOne />
      <!-- footer area end -->
    </main>
    <!-- main area end -->
    <!-- scrolltop button -->
    <a class="material-scrolltop" href="#"></a>
  </div>
</template>

<script>
import HeaderOne from "../Header.vue";
import HeroOne from "../Hero.vue";
import AboutOne from "../About.vue";
// import CounterOne from "../Counter.vue";
// import FeaturesOne from "../Features.vue";
// import VideoOne from "../Video.vue";
// import ScreenshotOne from '../Screenshot.vue';
// import CtaOne from "../Cta.vue";
// import PricingOne from '../Pricing.vue';
// import FaqOne from "../Faq.vue";
import TestimonialOne from "../Testimonial.vue";
// import BlogOne from '../Blog.vue';
import SubscribeOne from "../Subscribe.vue";
import ClientOne from "../Client.vue";
import FooterOne from "../Footer.vue";
//img import
import fe_icon1 from "@/assets/img/features/icon-1.png";
import fe_imgshp1 from "@/assets/img/features/shape-1.png";
import fe_icon2 from "@/assets/img/features/icon-2.png";
import fe_imgshp2 from "@/assets/img/features/shape-2.png";
import fe_icon3 from "@/assets/img/features/icon-3.png";
import fe_imgshp3 from "@/assets/img/features/shape-3.png";
export default {
  name: "HomeOne",
  components: {
    HeaderOne,
    HeroOne,
    AboutOne,
    // CounterOne,
    // FeaturesOne,
    // VideoOne,
    // CtaOne,
    TestimonialOne,
    SubscribeOne,
    ClientOne,
    FooterOne,
  },
  data() {
    return {
      itemsGrid: [
        {
          title: "데이터 정량화",
          para: "최신 트렌드의 훈련 프로그램, 축구 스타들의 경험과 노하우, 많이 사용하는 전술 등 정량화된 정보로 더 효울적으로 훈련할 수 있습니다.",
          icon: fe_icon1,
          imgshape: fe_imgshp1,
        },
        {
          title: "온라인 트레이닝 제작 툴",
          para: "온라인으로 손쉽게 훈련 프로그램을 제작하고, 이를 다른 사용자와 공유할 수 있습니다. 자신만의 훈련 프로그램을 세상에 널리 알릴 수 있는 기회입니다.",
          icon: fe_icon2,
          imgshape: fe_imgshp2,
        },
        {
          title: "프로그램 쉐어링",
          para: "여러분의 전략과 훈련 프로그램을 등록하고 판매할 수 있습니다. 여러분의 지혜를 공유하고 이를 통해 경제적 이익을 얻을 수 있습니다.",
          icon: fe_icon3,
          imgshape: fe_imgshp3,
        },
      ],
    };
  },

  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".material-scrolltop");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("reveal");
      } else {
        result.classList.remove("reveal");
      }
    },
  },
};
</script>

<style></style>
