<template>
    <div>
        <!-- header area start -->
        <HeaderOne />
        <!-- header area end -->
        <!-- main area start -->
        <main>
            <!-- breadcrumb area start -->
            <Breadcrumb title="shop" />
            <!-- breadcrumb area end -->
            <!-- products area start -->

            <div class="apork-shop-area mt-100 mb-50">
                <div class="container">
                    <div class="row">
                        <!-- single product area start -->
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12" v-for="item in count_items" :key="item.id">
                            <div class="apork-single-product-box mb-40">
                                <div class="single-product-top-area">
                                    <div class="product-str"></div>
                                    <div class="product-img">
                                        <img :src="item.image" alt="product-1" class="img-fluid" />
                                    </div>
                                </div>
                                <div class="apork-single-product-bottom-box">
                                    <div class="product-content">
                                        <ul class="product-tags" v-html="item.ctg"></ul>
                                        <h4>
                                            <a href="#" class="product-title">{{ item.title }}</a>
                                        </h4>
                                        <div class="product-price" v-html="item.price"></div>
                                        <ul class="product-action">
                                            <li>
                                                <a class="cart-link" href="#"><i class="fa fa-shopping-cart"></i></a>
                                            </li>
                                            <li>
                                                <a class="product-popup" href="assets/img/product/2.jpg"
                                                    data-toggle="tooltip" title="" data-original-title="view"><i
                                                        class="fa fa-eye"></i></a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-12">
                            <div class="apork-pagination shop-paginate text-center">
                                <nav>
                                    <ul class="pagination">
                                        <li class="page-item disabled" aria-disabled="true" aria-label="« Previous">
                                            <span class="page-link" aria-hidden="true">‹</span>
                                        </li>

                                        <li class="page-item active" aria-current="page">
                                            <span class="page-link">1</span>
                                        </li>
                                        <li class="page-item">
                                            <a class="page-link"
                                                href="https://laravel.workertm.com/apork/products?page=2">2</a>
                                        </li>

                                        <li class="page-item">
                                            <a class="page-link"
                                                href="https://laravel.workertm.com/apork/products?page=2" rel="next"
                                                aria-label="Next »">›</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- products area end -->
            <!-- footer area start -->
            <FooterTwo />
            <!-- footer area end -->
        </main>
        <!-- main area end -->

        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import Breadcrumb from "../Breadcrumb.vue";
import HeaderOne from "../Header.vue";
import FooterTwo from "../Footer.vue";
//product img
import img1 from "@/assets/img/product/1.jpg";
import img2 from "@/assets/img/product/2.jpg";
import img3 from "@/assets/img/product/3.jpg";
import img4 from "@/assets/img/product/4.jpg";
import img5 from "@/assets/img/product/5.jpg";
import img6 from "@/assets/img/product/6.jpg";
export default {
    name: "AboutUs",
    components: { HeaderOne, Breadcrumb, FooterTwo },
    data() {
        return {
            count_items: [
                {
                    image: img1,
                    title: "Single Vr Headset",
                    price: "price: $12",
                    ctg: '<li><a href="#">vr</a></li>',
                },
                {
                    image: img2,
                    title: "Black Smartwatch",
                    price: "price: $12 <del>$8</del>",
                    ctg: '<li><a href="#">watch</a></li> <li><a href="#">black</a></li> <li><a href="#">smart</a></li>',
                },
                {
                    image: img3,
                    title: "Soft Revolving Chair",
                    price: "price: $1800",
                    ctg: '<li><a href="#">revolving</a></li> <li><a href="#">chair</a></li>',
                },
                {
                    image: img4,
                    title: "Slim Laptop",
                    price: "price: $72450",
                    ctg: '<li><a href="#">slim</a></li> <li><a href="#">laptop</a></li>',
                },
                {
                    image: img5,
                    title: "Big Headphone With Jack",
                    price: "price: $870 <del>$1800</del>",
                    ctg: '<li><a href="#">big</a></li> <li><a href="#">headphone</a></li>',
                },
                {
                    image: img6,
                    title: "Big Headphone Wireless",
                    price: "price: $65 <del>$794</del>",
                    ctg: '<li><a href="#">wireless</a></li> <li><a href="#">headphone</a></li>',
                },
            ],
        };
    },
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        topToBottom() {
            const result = document.querySelector('.material-scrolltop')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
    }
};
</script>

<style>

</style>
