<template>
    <div id="apork-hero" class="apork-hero-area hero-bg position-relative">
        <div class="hero-style-shape">
            <img class="style-shape shape-1" src="@/assets/img/hero/shape-1.png" alt="" />
            <img class="style-shape shape-2" src="@/assets/img/hero/shape-2.png" alt="" />
            <img class="style-shape aporkrot shape-3" src="@/assets/img/hero/shape-3.png" alt="" />
        </div>
        <div class="container position-relative">
            <div class="row">
                <div class="col-xl-7 col-lg-8 col-md-12">
                    <div class="hero-content">
                        <h2 v-html="title"></h2>
                        <p>{{ paragraph }}</p>
                        <br>
                    </div>
                </div>
                <div class="col-xl-4 offset-xl-1 col-lg-4 wow fadeInRight">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HeroOne',
    data() {
        return {
            title: "현장 레슨? No!<br> 이제 온라인으로 축구 배우자!",
            paragraph: "한명에서 한팀까지 모두가 성장하는 스포츠 솔루션",
            // btn: "Read More",
            // btn2: "LEARN MORE",
        }
    }
}
</script>

<style>

</style>