import { render, staticRenderFns } from "./productlist-pro.vue?vue&type=template&id=1b264055&"
import script from "./productlist-pro.vue?vue&type=script&lang=js&"
export * from "./productlist-pro.vue?vue&type=script&lang=js&"
import style0 from "./productlist-pro.vue?vue&type=style&index=0&id=1b264055&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports