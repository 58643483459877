import Vue from "vue";
import VueRouter from "vue-router";
import HomeOne from "../components/Pages/index";
import About from "../components/Pages/about";
import Service from "../components/Pages/service";
import Plan from "../components/Pages/plan";
import Shop from "../components/Pages/shop";
import BlogList from "../components/Pages/bloglist";
import BlogGrid from "../components/Pages/bloggrid";
import Contact from "../components/Pages/contact";
import Checkout from "../components/Pages/checkout";
import ProductList from "../components/Pages/productlist";
import NewsLetter from "../components/Pages/newsletter";
import DetailProduct from "../components/Pages/detailproduct";
import FaqPage from "../components/Pages/faqpage";
import Login from "../components/Pages/login";
import Join from "../components/Pages/join";
import Newcontact from "../components/Pages/newcontact";
import ProductListBest from "../components/Pages/productlist-best";
import ProductListProgram from "../components/Pages/productlist-program";
import ProductListEducation from "../components/Pages/productlist-education";
import ProductListPro from "../components/Pages/productlist-pro";
import ProductListYouth from "../components/Pages/productlist-youth";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: HomeOne,
  },
  {
    path: "/about",
    component: About,
  },
  {
    path: "/service",
    component: Service,
  },
  {
    path: "/plan",
    component: Plan,
  },
  {
    path: "/shop",
    component: Shop,
  },
  {
    path: "/blog-list",
    component: BlogList,
  },
  {
    path: "/blog-grid",
    component: BlogGrid,
  },
  {
    path: "/checkout",
    component: Checkout,
  },
  {
    path: "/contact",
    component: Contact,
  },
  {
    path: "/product-list",
    component: ProductList,
  },
  {
    path: "/newsletter",
    component: NewsLetter,
  },
  {
    path: "/product/:id",
    name: "product",
    component: DetailProduct,
  },
  {
    path: "/faq",
    component: FaqPage,
  },
  {
    path: "/login",
    component: Login,
  },
  {
    path: "/join",
    component: Join,
  },
  {
    path: "/newcontact",
    component: Newcontact,
  },
  {
    path: "/product-list-best",
    component: ProductListBest,
  },
  {
    path: "/product-list-program",
    component: ProductListProgram,
  },
  {
    path: "/product-list-education",
    component: ProductListEducation,
  },
  {
    path: "/product-list-pro",
    component: ProductListPro,
  },
  {
    path: "/product-list-youth",
    component: ProductListYouth,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // stop stop cache
  scrollBehavior() {
    document.getElementById("app").scrollIntoView();
  },
});

export default router;
