<template>
  <div>
    <!-- header area start -->
    <HeaderOne />
    <!-- header area end -->
    <!-- main area start -->
    <main>
      <!-- breadcrumb area start -->
      <Breadcrumb
        title="GRAND OPENING"
        sub-title="스포바이에서 제공하는 모든 자료를 무료로 즐겨보세요."
      />
      <!-- breadcrumb area end -->
      <div
        id="apork-features-area"
        class="apork-features-area position-relative mt-110 mb-110"
      >
        <div class="features-bg-shape">
          <img
            src="@/assets/img/features/bg-shape-1.png"
            alt=""
            class="style-shape fbs-1"
          />
          <img
            src="@/assets/img/features/bg-shape-2.png"
            alt=""
            class="style-shape fbs-2"
          />
        </div>
        <div class="container">
          <div class="row text-center">
            <div
              class="col-xl-4 col-lg-4 col-md-4 wow fadeInLeft"
              v-for="(item, index) in itemsGrid"
              :key="index"
            >
              <div class="single-features">
                <img :src="item.icon" alt="" />
                <h3>{{ item.title }}</h3>
                <p>{{ item.para }}</p>
                <div class="features-shape">
                  <img :src="item.imgshape" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- footer area start -->
      <FooterTwo />
      <!-- footer area end -->
    </main>
    <!-- main area end -->

    <!-- scrolltop button -->
    <a class="material-scrolltop" href="#"></a>
  </div>
</template>

<script>
import HeaderOne from "../Header.vue";
import Breadcrumb from "../Breadcrumb.vue";
import FooterTwo from "../Footer.vue";
//img import
import fe_icon1 from "@/assets/img/features/icon-1.png";
import fe_imgshp1 from "@/assets/img/features/shape-1.png";
import fe_icon2 from "@/assets/img/features/icon-2.png";
import fe_imgshp2 from "@/assets/img/features/shape-2.png";
import fe_icon3 from "@/assets/img/features/icon-3.png";
import fe_imgshp3 from "@/assets/img/features/shape-3.png";
export default {
  name: "ServiceOne",
  components: { HeaderOne, Breadcrumb, FooterTwo },
  data() {
    return {
      itemsGrid: [
        {
          title: "데이터 정량화",
          para: "최신 트렌드의 훈련 프로그램, 축구 스타들의 경험과 노하우, 많이 사용하는 전술 등 정량화된 정보로 더 효울적으로 훈련할 수 있습니다.",
          icon: fe_icon1,
          imgshape: fe_imgshp1,
        },
        {
          title: "온라인 트레이닝 제작 툴",
          para: "온라인으로 손쉽게 훈련 프로그램을 제작하고, 이를 다른 사용자와 공유할 수 있습니다. 자신만의 훈련 프로그램을 세상에 널리 알릴 수 있는 기회입니다.",
          icon: fe_icon2,
          imgshape: fe_imgshp2,
        },
        {
          title: "프로그램 쉐어링",
          para: "여러분의 전략과 훈련 프로그램을 등록하고 판매할 수 있습니다. 여러분의 지혜를 공유하고 이를 통해 경제적 이익을 얻을 수 있습니다.",
          icon: fe_icon3,
          imgshape: fe_imgshp3,
        },
      ],
    };
  },
  mounted() {
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    topToBottom() {
      const result = document.querySelector(".material-scrolltop");
      if (
        document.body.scrollTop > window.innerHeight ||
        document.documentElement.scrollTop > window.innerHeight
      ) {
        result.classList.add("reveal");
      } else {
        result.classList.remove("reveal");
      }
    },
  },
};
</script>

<style></style>
