<template>
  <div>
    <HeaderOne />
    <div class="product-detail-best">
      <Breadcrumb
        title="GRAND OPENING"
        sub-title="모든 자료를 무료로 즐겨보세요."
      />

      <div class="contents-box-wraper">
        <div class="contents-box">
          <!-- 사이드 바 시작 -->
          <div class="side-nav-wraper">
            <div class="side-nav-line"></div>
            <h3 class="side-nav-title">인기 카테고리</h3>
            <ul class="side-nav-item">
              <li class="nav-li">
                <a href="/product-list" class="nav-li-02-a">전체</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-best" class="nav-li-03-a">BEST</a
                ><span>></span>
              </li>
              <div class="side-nav-item-bg-01">
                <li class="nav-li-01">
                  <a href="/product-list-program" class="nav-li-01-a"
                    >프로그램</a
                  ><span>></span>
                </li>
              </div>
              <li class="nav-li">
                <a href="/product-list-education" class="nav-li-04-a"
                  >교육 강의</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-pro" class="nav-li-05-a">프로 구단</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-youth" class="nav-li-06-a">유소년</a
                ><span>></span>
              </li>
            </ul>
          </div>

          <!-- 메인 컨텐츠 시작 -->
          <div class="main-con-wraper">
            <div class="main-con-bg">
              <product
                v-for="(product, index) in products"
                :key="`product${index}`"
                :product="product"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import HeaderOne from "../Header.vue";
import Footer from "../Footer.vue";
import Breadcrumb from "../Breadcrumb.vue";
import Product from "../Product.vue";

export default {
  name: "ProductListProgram",
  components: { HeaderOne, Footer, Breadcrumb, Product },
  data() {
    return {
      products: [
        {
          id: 1,
          title: "Endurance into a Game",
          description: "피지컬 강화를 위한 4vs4 레이스 게임",
          image: require("/src/assets/img/new/insta-12.png"),
          image2: require("/src/assets/img/sub-img/insta-12-01.png"),
          image3: require("/src/assets/img/sub-img/insta-12-02.png"),
          youtubeUrl: "https://youtu.be/5nneJtB7NMM",
          downloadUrl: "/video/Endurance into a Game.mp4",
          length: "00:29",
          number: "252",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 8명",
          age: "14 ~",
        },
        {
          id: 2,
          title: "6인 슈팅 프로그램",
          description: "슈팅 + 패싱 향상 6인 프로그램",
          image: require("/src/assets/img/new/insta-18.png"),
          image2: require("/src/assets/img/sub-img/insta-18-01.png"),
          image3: require("/src/assets/img/sub-img/insta-18-02.png"),
          youtubeUrl: "https://youtu.be/9Y8GhY7uZk4",
          downloadUrl: "/video/6인 슈팅 프로그램.mp4",
          length: "00:08",
          number: "139",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 6명",
          age: "13 ~ 16",
        },
        {
          id: 3,
          title: "혼자서 하는 드리블링",
          description: "풋워크와 드리블을 결합한 1인 훈련 프로그램",
          image: require("/src/assets/img/new/insta-14.png"),
          image2: require("/src/assets/img/sub-img/insta-14-01.png"),
          image3: require("/src/assets/img/sub-img/insta-14-02.png"),
          youtubeUrl: "https://youtu.be/PeXuqbZZu_0",
          downloadUrl: "/video/혼자서 하는 드리블링.mp4",
          length: "00:17",
          number: "241",
          rank: "무료",
          strength: "중상",
          participation: "1명",
          age: "13 ~ 20",
        },
        {
          id: 4,
          title: "[드리블+슈팅] 6인 1조",
          description:
            "6명씩 한 그룹으로 드리블과 슈팅까지 한번에 연결하는 훈련!",
          image: require("/src/assets/img/new/insta-02.png"),
          image2: require("/src/assets/img/sub-img/insta-02-01.png"),
          image3: require("/src/assets/img/sub-img/insta-02-02.png"),
          youtubeUrl: "https://youtu.be/mexd9Y_5Aos",
          downloadUrl: "/video/[드리블+슈팅] 6인 1조.mp4",
          length: "00:08",
          number: "131",
          rank: "무료",
          strength: "중하",
          participation: "그룹 - 6명",
          age: "10 ~ 19",
        },
        {
          id: 5,
          title: "소프트한 터치를 위한 스킬",
          description: "마커 3개만 있으면 되는 1인 드리블 훈련",
          image: require("/src/assets/img/new/insta-15.png"),
          image2: require("/src/assets/img/sub-img/insta-15-01.png"),
          image3: require("/src/assets/img/sub-img/insta-15-02.png"),
          youtubeUrl: "https://youtu.be/gtI9CiEknD0",
          downloadUrl: "/video/소프트한 터치를 위한 스킬.mp4",
          length: "00:21",
          number: "221",
          rank: "무료",
          strength: "중하",
          participation: "1명",
          age: "9 ~ 16",
        },
        {
          id: 6,
          title: "Finishing Circuit",
          description: "골 결정력 강화를 위한 서킷",
          image: require("/src/assets/img/new/insta-09.png"),
          image2: require("/src/assets/img/sub-img/insta-09-01.png"),
          image3: require("/src/assets/img/sub-img/insta-09-02.png"),
          youtubeUrl: "https://youtu.be/gsaoQyI4QsU",
          downloadUrl: "/video/Finishing Circuit.mp4",
          length: "00:37",
          number: "587",
          rank: "무료",
          strength: "중상",
          participation: "그룹 - 9명",
          age: "13 ~",
        },
        {
          id: 7,
          title: "혼자서도 가능한 종합 훈련",
          description: "이 훈련은 모든 종류의 움직임과 기술을 포함합니다.",
          image: require("/src/assets/img/new/insta-03.png"),
          image2: require("/src/assets/img/sub-img/insta-03-01.png"),
          image3: require("/src/assets/img/sub-img/insta-03-02.png"),
          youtubeUrl: "https://youtu.be/-0y9TSvfdV8",
          downloadUrl: "/video/혼자서도 가능한 종합 훈련.mp4",
          length: "00:24",
          number: "212",
          rank: "무료",
          strength: "중상",
          participation: "1명",
          age: "13 ~ 19",
        },
        {
          id: 8,
          title: "1 v 1 Games",
          description: "스프린트, 헤딩 1vs1 레이스",
          image: require("/src/assets/img/new/insta-10.png"),
          image2: require("/src/assets/img/sub-img/insta-10-01.png"),
          image3: require("/src/assets/img/sub-img/insta-10-02.png"),
          youtubeUrl: "https://youtu.be/IuOPkyAPlLg",
          downloadUrl: "/video/1 v 1 Games.mp4",
          length: "00:30",
          number: "608",
          rank: "무료",
          strength: "중상",
          participation: "그룹 - 4명",
          age: "12 ~ 20",
        },
        {
          id: 9,
          title: "슬라럼 드리블 슈팅 훈련",
          description: "드리블에서 나오는 슈팅을 향상시키는 훌륭한 드릴 세션!",
          image: require("/src/assets/img/new/insta-04.png"),
          image2: require("/src/assets/img/sub-img/insta-04-01.png"),
          image3: require("/src/assets/img/sub-img/insta-04-02.png"),
          youtubeUrl: "https://youtu.be/6UDLGHIDOD8",
          downloadUrl: "/video/슬라럼 드리블 슈팅 훈련.mp4",
          length: "00:40",
          number: "97",
          rank: "무료",
          strength: "중하",
          participation: "1명",
          age: "11 ~ 20",
        },
        {
          id: 10,
          title: "[여자 축구] 피지컬 강화",
          description: "여자 축구 최강팀에서 보여주는 피지컬 강화 훈련",
          image: require("/src/assets/img/new/insta-06.png"),
          image2: require("/src/assets/img/sub-img/insta-06-01.png"),
          image3: require("/src/assets/img/sub-img/insta-06-02.png"),
          youtubeUrl: "https://youtu.be/7N7gsC90csk",
          downloadUrl: "/video/[여자 축구] 피지컬 강화.mp4",
          length: "00:12",
          number: "177",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 5명",
          age: "16 ~",
        },
        {
          id: 11,
          title: "2인1조 드리블 레이스",
          description: "드리블과 슈팅을 섞은 2인 1조 레이스 게임",
          image: require("/src/assets/img/new/insta-07.png"),
          image2: require("/src/assets/img/sub-img/insta-07-01.png"),
          image3: require("/src/assets/img/sub-img/insta-07-02.png"),
          youtubeUrl: "https://youtu.be/wiKXLiA1q0c",
          downloadUrl: "/video/2인1조 드리블 레이스.mp4",
          length: "00:12",
          number: "374",
          rank: "무료",
          strength: "중",
          participation: "2명",
          age: "10 ~ 20",
        },
        {
          id: 12,
          title: "focuses on teamwork",
          description: "4vs4 SSG 프로그램",
          image: require("/src/assets/img/new/insta-08.png"),
          image2: require("/src/assets/img/sub-img/insta-08-01.png"),
          image3: require("/src/assets/img/sub-img/insta-08-02.png"),
          youtubeUrl: "https://youtu.be/oMEUng7aetw",
          downloadUrl: "/video/focuses on teamwork.mp4",
          length: "00:41",
          number: "439",
          rank: "무료",
          strength: "중상",
          participation: "그룹 - 4명",
          age: "13 ~ 20",
        },
        {
          id: 13,
          title: "Passing Combinations",
          description: "패싱을 향상시키는 스핀 패싱 프로그램",
          image: require("/src/assets/img/new/insta-11.png"),
          image2: require("/src/assets/img/sub-img/insta-11-01.png"),
          image3: require("/src/assets/img/sub-img/insta-11-02.png"),
          youtubeUrl: "https://youtu.be/SQKSkgXkWI4",
          downloadUrl: "/video/Passing Combinations.mp4",
          length: "00:35",
          number: "422",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 5명",
          age: "13 ~ 20",
        },
        {
          id: 14,
          title: "풋워크 + 스텝",
          description: "측면 점프와 멀리뛰기를 결합한 코디네이션",
          image: require("/src/assets/img/new/insta-13.png"),
          image2: require("/src/assets/img/sub-img/insta-13-01.png"),
          image3: require("/src/assets/img/sub-img/insta-13-02.png"),
          youtubeUrl: "https://youtu.be/dIWluMwnsYU",
          downloadUrl: "/video/풋워크 + 스텝.mp4",
          length: "00:15",
          number: "295",
          rank: "무료",
          strength: "중",
          participation: "1명",
          age: "13 ~ 16",
        },
        {
          id: 15,
          title: "1vs1 드릴 프로그램",
          description: "좁은 공간을 활용한 1vs1 프로그램",
          image: require("/src/assets/img/new/insta-16.png"),
          image2: require("/src/assets/img/sub-img/insta-16-01.png"),
          image3: require("/src/assets/img/sub-img/insta-16-02.png"),
          youtubeUrl: "https://youtu.be/tfkltm6tKPs",
          downloadUrl: "/video/1vs1 드릴 프로그램.mp4",
          length: "00:09",
          number: "122",
          rank: "무료",
          strength: "중",
          participation: "2명",
          age: "10 ~ 16",
        },
        {
          id: 16,
          title: "8명이서 하는 패싱 드릴",
          description: "움직임과 리시빙을 향상하는 순환 프로그램",
          image: require("/src/assets/img/new/insta-17.png"),
          image2: require("/src/assets/img/sub-img/insta-17-01.png"),
          image3: require("/src/assets/img/sub-img/insta-17-02.png"),
          youtubeUrl: "https://youtu.be/hSPpK0luutY",
          downloadUrl: "/video/8명이서 하는 패싱 드릴.mp4",
          length: "00:07",
          number: "173",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 8명",
          age: "12 ~ 20",
        },
      ],
    };
  },
};
</script>
<style>
.product-detail {
  height: 100%;
}
.contents-box-wraper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.contents-box {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* 사이드 바 */
.side-nav-wraper {
  margin-top: 40px;
  width: 11.5%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.side-nav-line {
  width: 100%;
  height: 5px;
  background-color: #002870;
  margin-bottom: 15px;
}
.side-nav-title {
  font-size: 25px;
  font-weight: 700;
}
.side-nav-item-bg-01 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(0, 40, 112, 0.1);
}
.side-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 100%;
}
.side-nav-item li {
  padding: 10px 0;
  font-size: 17px;
  font-weight: 500;
  width: 80%;
}

.nav-li {
  display: flex;
  justify-content: space-between;
}
.nav-li-01 {
  color: #002870;
  display: flex;
  justify-content: space-between;
}
.nav-li-01-a {
  color: #002870;
}
.nav-li-02-a,
.nav-li-03-a,
.nav-li-04-a,
.nav-li-05-a,
.nav-li-06-a {
  color: black;
}

/* 메인 콘텐츠 */
.main-con-wraper {
  margin-top: 40px;
  width: 80%;
  height: 100%;
}
.main-con-bg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* 콘텐츠 01 */
.con-img {
  border: 1px solid #969696;
  border-radius: 20px;
  height: 200px;
  background-image: url(@/assets/img/new/youtube-02.png);
  background-size: cover;
}
.main-con-item {
  width: 20%;
  height: 100%;
  margin-right: 30px;
  margin-bottom: 50px;
}
.main-con-txt {
  margin-top: 15px;
}
.main-con-a {
  color: black;
  font-size: 17px;
  font-weight: 700;
}
.main-con-p {
  color: #969696;
  font-size: 15px;
  font-weight: 500;
}
</style>
