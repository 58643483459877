<template>
    <div>
        <!-- header area start -->
        <HeaderOne />
        <!-- header area end -->
        <!-- main area start -->
        <main>
            <!-- breadcrumb area start -->
            <Breadcrumb title="contact" />
            <!-- breadcrumb area end -->
            <!-- about area start -->

            <!-- contact area start -->
            <div id="apork-contact-area" class="apork-contact-area mt-110 mb-110">
                <!-- contact info area start -->

                <div id="contact-info-area" class="contact-info-area">
                    <div class="container">
                        <div class="row text-center">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 wow fadeInLeft" v-for="item in items"
                                :key="item.id">
                                <div class="contact-info-box">
                                    <div class="contact-info-icon">
                                        <img :src="item.img" alt="" />
                                    </div>
                                    <ul class="contact-info" v-html="item.info"></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- contact info area end -->
                <!-- contact map area start -->
                <div>
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-12">
                                <GmapMap :center="{ lat: 40.67, lng: -73.94 }" :zoom="11" map-type-id="terrain"
                                    id="apork-map" class="mt-80 mb-80">
                                    <GmapMarker :key="index" v-for="(m, index) in markers" :position="m.position"
                                        :clickable="true" :draggable="true" @click="center = m.position" />
                                </GmapMap>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- contact map area end -->
                <!-- contact form area start -->
                <form class="apork-contact-form" id="ajax-contact" ref="form" @submit.prevent="sendEmail">
                    <div class="container">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <input class="form-control" type="text" name="name" placeholder="Name *" />
                            </div>
                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <input class="form-control" type="email" name="email" placeholder="E-mail *" />
                            </div>

                            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                <input class="form-control" type="text" name="subject" placeholder="Subject" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-12">
                                <textarea class="form-control" name="message" cols="30" rows="8"
                                    placeholder="Request"></textarea>
                                <button type="submit" class="btn btn-type-6">submit</button>
                            </div>
                        </div>
                        <!-- <p class="form-message float-left mt-15 error text-danger">Please complete the form and try again.</p> -->
                        <div v-if="showResult" class="success-msg mt-15">
                            <p>{{ resultText }}</p>
                        </div>
                    </div>
                </form>
                <!-- contact form area end -->
            </div>
            <!-- contact area end -->
            <!-- about area end -->
            <!-- footer area start -->
            <FooterTwo />
            <!-- footer area end -->
        </main>
        <!-- main area end -->

        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import emailjs from 'emailjs-com';
import Breadcrumb from "../Breadcrumb.vue";
import HeaderOne from "../Header.vue";
import FooterTwo from "../Footer.vue";
//contact image
import img1 from "@/assets/img/contact/icon-1.png";
import img2 from "@/assets/img/contact/icon-2.png";
import img3 from "@/assets/img/contact/icon-3.png";
export default {
    name: "ContactOne",
    components: { HeaderOne, Breadcrumb, FooterTwo },
    data() {
        return {
            resultText: '',
            showResult: false,
            items: [
                {
                    img: img1,
                    info: "<li>+1 (234) 567-8991</li> <li>+1 (234) 567-8991</li>",
                },
                {
                    img: img2,
                    info: "<li>189 Matex Street, Badly Avenue</li> <li>New York, NY-12548</li>",
                },
                {
                    img: img3,
                    info: '<li><a href="mailto:apork@website.com">apork@website.com</a></li> <li><a href="mailto:sales@website.com">sales@website.com</a></li>',
                },
            ],
        };
    },
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        sendEmail(e) {
            emailjs.sendForm(
                'YOUR_SERVICE_ID',
                'YOUR_TEMPLATE_ID',
                this.$refs.form, 'YOUR_PUBLIC_KEY'
            )
                .then((result) => {
                    this.showResult = true;
                    this.resultText = 'Your message has been sent successfully. We will contact you soon.';
                    setTimeout(() => {
                        this.showResult = false;
                    }, 5000);
                    e.target.reset();
                    console.log('SUCCESS!', result.text);
                }, (error) => {
                    this.showResult = true;
                    this.resultText = error.text;
                    setTimeout(() => {
                        this.showResult = false;
                    }, 5000);
                    console.log('FAILED...', error.text);
                });
        },
        topToBottom() {
            const result = document.querySelector('.material-scrolltop')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
    }
};
</script>

<style>

</style>
