import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import * as VueGoogleMaps from "vue2-google-maps";
// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "../src/assets/css/fontawesome-all.min.css";
import "../src/assets/css/animate.min.css";
import "../src/assets/css/default.css";
import "../src/assets/css/scrolltop.css";
import "../src/assets/css/style.css";
import "../src/assets/css/responsive.css";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCJRG4KqGVNvAPY4UcVDLcLNXMXk2ktNfY",
    libraries: "places",
  },
});
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.config.productionTip = false;

new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
