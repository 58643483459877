<template>
  <div
    id="apork-subscribe-area"
    class="apork-subscribe-area pt-110 pb-110 wow fadeInUp text-center"
  >
    <div class="subscribe-overlay"></div>
    <div class="container position-relative">
      <div class="row">
        <div class="col-xl-8 col-lg-8 mx-auto">
          <div class="subscribe-title-para">
            <h3>{{ title }}</h3>
            <p>{{ paragraph }}</p>
            <p>{{ paragraph2 }}</p>
          </div>
          <div class="subscribe-form text-center">
            <form action="#">
              <input
                class="form-control"
                type="email"
                placeholder="Type Your Email"
              />
              <button
                type="submit"
                class="btn btn-type-2"
                onclick="alert('🎉 구독을 축하드립니다')"
              >
                {{ btn_title }}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubscribeOne",
  data() {
    return {
      btn_title: "구독하기",
      title: "축구 뉴스레터로 세계 축구 소식 알아가기!",
      paragraph:
        "스포바이 뉴스레터를 구독하면 당신은 축구의 매력적인 세계로 초대받게 됩니다. 우리와 축구의 열정을 나누며 새로운 소식을 발견하세요.",
      paragraph2: "지금 구독하고 세계 축구의 소식을 놓치지 마세요!",
    };
  },
};
</script>

<style>
.subscribe-title-para h3 {
  margin-bottom: 20px;
}
</style>
