<template>
    <div id="apork-client-slider" class="apork-client-slider pt-110 pb-110">
        <div class="container">
            <div class="row">
                <div class="col-xl-10 col-lg-10 col-md-12 mx-auto">
                    <div class="all-client-slider">
                        <carousel :autoplay="true" :items="4" :nav="false" :loop="true" :dots="false" :smartSpeed="1000"
                            :responsive="{
                                0: {
                                    items: 1
                                },
                                600: {
                                    items: 2
                                },
                                1000: {
                                    items: 4
                                }
                            }" responsive-base-element="body">
                        <!-- single client start -->
                        <a href="#">
                            <div class="single-client">
                                <img src="@/assets/img/client/partner-01.png" alt="" class="img-fluid" />
                            </div>
                        </a>
                        <!-- single client end -->

                        <!-- single client start -->
                        <a href="#">
                            <div class="single-client">
                                <img src="@/assets/img/client/partner-10.png" alt="" class="img-fluid" />
                            </div>
                        </a>
                        <!-- single client end -->

                        <!-- single client start -->
                        <a href="#">
                            <div class="single-client">
                                <img src="@/assets/img/client/partner-03.png" alt="" class="img-fluid" />
                            </div>
                        </a>
                        <!-- single client end -->

                        <!-- single client start -->
                        <a href="#">
                            <div class="single-client">
                                <img src="@/assets/img/client/partner-08.png" alt="" class="img-fluid" />
                            </div>
                        </a>
                        <!-- single client end -->

                        <!-- single client start -->
                        <a href="#">
                            <div class="single-client">
                                <img src="@/assets/img/client/partner-11.png" alt="" class="img-fluid" />
                            </div>
                        </a>
                        <!-- single client end -->
                            </carousel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import carousel from 'vue-owl-carousel2'
export default {
    name: 'ClientOne',
    components: { carousel },
}
</script>

<style>

</style>