<template>
  <div>
    <HeaderOne />
    <div class="product-detail-best">
      <Breadcrumb
        title="GRAND OPENING"
        sub-title="모든 자료를 무료로 즐겨보세요."
      />

      <div class="contents-box-wraper">
        <div class="contents-box">
          <!-- 사이드 바 시작 -->
          <div class="side-nav-wraper">
            <div class="side-nav-line"></div>
            <h3 class="side-nav-title">인기 카테고리</h3>
            <ul class="side-nav-item">
              <li class="nav-li">
                <a href="/product-list" class="nav-li-02-a">전체</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-best" class="nav-li-03-a">BEST</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-program" class="nav-li-04-a"
                  >프로그램</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-education" class="nav-li-05-a"
                  >교육 강의</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-pro" class="nav-li-06-a">프로 구단</a
                ><span>></span>
              </li>
              <div class="side-nav-item-bg-01">
                <li class="nav-li-01">
                  <a href="/product-list-youth" class="nav-li-01-a">유소년</a
                  ><span>></span>
                </li>
              </div>
            </ul>
          </div>

          <!-- 메인 컨텐츠 시작 -->
          <div class="main-con-wraper">
            <div class="main-con-bg">
              <product
                v-for="(product, index) in products"
                :key="`product${index}`"
                :product="product"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import HeaderOne from "../Header.vue";
import Footer from "../Footer.vue";
import Breadcrumb from "../Breadcrumb.vue";
import Product from "../Product.vue";

export default {
  name: "ProductListYouth",
  components: { HeaderOne, Footer, Breadcrumb, Product },
  data() {
    return {
      products: [
        {
          id: 1,
          title: "[KFA 아카데미] 드리블&페인팅",
          description:
            "KFA 전임 지도자 송승준 코치가 알려주는 유소년 드리블 훈련",
          image: require("/src/assets/img/new/youtube-12.png"),
          image2: require("/src/assets/img/sub-img/youtube-12-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-12-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=1zu_vRD4Z3A&t=26s",
          downloadUrl: "/video/[KFA 아카데미] 드리블&페인팅.mp4",
          length: "08:52",
          number: "376",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 10명",
          age: "10 ~ 15",
        },
        {
          id: 2,
          title: "[KFA 골든에이지] 골키퍼 빌드업",
          description: "골키퍼가 빌드업을 하기 위해서 가장 중요한 요소",
          image: require("/src/assets/img/new/youtube-23.png"),
          image2: require("/src/assets/img/sub-img/youtube-23-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-23-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=CbJMtXY7KsA&t=35s",
          downloadUrl: "/video/[KFA 골든에이지] 골키퍼 빌드업.mp4",
          length: "07:11",
          number: "773",
          rank: "무료",
          strength: "중하",
          participation: "1 ~ 3명",
          age: "11 ~ 16",
        },
        {
          id: 3,
          title: "[이호욱 강사] 영양학 특강",
          description: "유소년 선수들을 위한 영양학 특강",
          image: require("/src/assets/img/new/youtube-24.png"),
          image2: require("/src/assets/img/sub-img/youtube-24-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-24-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=A6Ga6g0oM60&t=183s",
          downloadUrl: "/video/[이호욱 강사] 영양학 특강.mp4",
          length: "12:48",
          number: "429",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "8 ~ 19",
        },
        {
          id: 4,
          title: "[미하엘 뮐러] 다양한 1vs1 훈련",
          description: "U11-U12 대상 선수들을 위한 1vs1 훈련",
          image: require("/src/assets/img/new/youtube-25.png"),
          image2: require("/src/assets/img/sub-img/youtube-25-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-25-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=0bA6d0m-6BY&t=302s",
          downloadUrl: "/video/[미하엘 뮐러] 다양한 1vs1 훈련.mp4",
          length: "06:01",
          number: "352",
          rank: "무료",
          strength: "중",
          participation: "2명",
          age: "11 ~ 12",
        },
        {
          id: 5,
          title: "[KFA] 인지능력 향상 1편",
          description: "오성환 코치가 알려주는 인지능력 향상 1편",
          image: require("/src/assets/img/new/youtube-26.png"),
          image2: require("/src/assets/img/sub-img/youtube-26-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-26-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=OMKbXiWOZfA&t=124s",
          downloadUrl: "/video/[KFA] 인지능력 향상 1편.mp4",
          length: "05:56",
          number: "582",
          rank: "무료",
          strength: "없음",
          participation: "없음",
          age: "8 ~ 16",
        },
        {
          id: 6,
          title: "[KFA] 인지능력 향상 2편",
          description: "오성환 코치가 알려주는 인지능력 향상 2편",
          image: require("/src/assets/img/new/youtube-27.png"),
          image2: require("/src/assets/img/sub-img/youtube-27-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-27-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=XaNB9hqx6wk&t=98s",
          downloadUrl: "/video/[KFA] 인지능력 향상 2편.mp4",
          length: "06:29",
          number: "552",
          rank: "무료",
          strength: "중",
          participation: "1 ~ 2명",
          age: "10 ~ 13",
        },
        {
          id: 7,
          title: "[KFA] 인지능력 향상 3편",
          description: "오성환 코치가 알려주는 인지능력 향상 3편",
          image: require("/src/assets/img/new/youtube-28.png"),
          image2: require("/src/assets/img/sub-img/youtube-28-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-28-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=K5j7m9pc4tM&t=38s",
          downloadUrl: "/video/[KFA] 인지능력 향상 3편.mp4",
          length: "05:50",
          number: "431",
          rank: "무료",
          strength: "중상",
          participation: "그룹 - 7명",
          age: "10 ~ 16",
        },
        {
          id: 8,
          title: "[KFA] 인지능력 향상 4편",
          description: "오성환 코치가 알려주는 인지능력 향상 4편",
          image: require("/src/assets/img/new/youtube-11.png"),
          image2: require("/src/assets/img/sub-img/youtube-11-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-11-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=yxDZ7KTWND4",
          downloadUrl: "/video/[KFA] 인지능력 향상 4편.mp4",
          length: "05:54",
          number: "610",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 7명",
          age: "10 ~ 16",
        },
      ],
    };
  },
};
</script>
<style>
.product-detail {
  height: 100%;
}
.contents-box-wraper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.contents-box {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* 사이드 바 */
.side-nav-wraper {
  margin-top: 40px;
  width: 11.5%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.side-nav-line {
  width: 100%;
  height: 5px;
  background-color: #002870;
  margin-bottom: 15px;
}
.side-nav-title {
  font-size: 25px;
  font-weight: 700;
}
.side-nav-item-bg-01 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(0, 40, 112, 0.1);
}
.side-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 100%;
}
.side-nav-item li {
  padding: 10px 0;
  font-size: 17px;
  font-weight: 500;
  width: 80%;
}

.nav-li {
  display: flex;
  justify-content: space-between;
}
.nav-li-01 {
  color: #002870;
  display: flex;
  justify-content: space-between;
}
.nav-li-01-a {
  color: #002870;
}
.nav-li-02-a,
.nav-li-03-a,
.nav-li-04-a,
.nav-li-05-a,
.nav-li-06-a {
  color: black;
}

/* 메인 콘텐츠 */
.main-con-wraper {
  margin-top: 40px;
  width: 80%;
  height: 100%;
}
.main-con-bg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* 콘텐츠 01 */
.con-img {
  border: 1px solid #969696;
  border-radius: 20px;
  height: 200px;
  background-image: url(@/assets/img/new/youtube-02.png);
  background-size: cover;
}
.main-con-item {
  width: 20%;
  height: 100%;
  margin-right: 30px;
  margin-bottom: 50px;
}
.main-con-txt {
  margin-top: 15px;
}
.main-con-a {
  color: black;
  font-size: 17px;
  font-weight: 700;
}
.main-con-p {
  color: #969696;
  font-size: 15px;
  font-weight: 500;
}
</style>
