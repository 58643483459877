<template>
    <div>
        <!-- header area start -->
        <HeaderOne />
        <!-- header area end -->
        <!-- main area start -->
        <main>
            <!-- breadcrumb area start -->
            <Breadcrumb title="checkout" />
            <!-- breadcrumb area end -->
            <!-- apork checkout area start -->
            <div class="apork-checkout-area mt-100 mb-50">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8">
                            <div class="billing-area mb-30">
                                <h3 class="apork-heading-title">billing address</h3>
                                <div class="container">
                                    <div class="row">
                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div class="form-group mb-3">
                                                <label class="form-label" for="fuser_name">
                                                    <h6>name</h6>
                                                </label>
                                                <input id="fuser_name" class="form-control" type="text"
                                                    name="billing_name" placeholder="name *" />
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div class="form-group mb-3">
                                                <label class="form-label" for="fuser_email">
                                                    <h6>email</h6>
                                                </label>
                                                <input id="fuser_email" class="form-control" type="text"
                                                    name="billing_email" placeholder="email *" />
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div class="form-group mb-3">
                                                <label class="form-label" for="fuser_phone">
                                                    <h6>phone</h6>
                                                </label>
                                                <input id="fuser_phone" class="form-control" type="text"
                                                    name="billing_number" placeholder="phone *" />
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div class="form-group mb-3">
                                                <label class="form-label" for="fuser_country">
                                                    <h6>country</h6>
                                                </label>
                                                <input id="fuser_country" class="form-control" type="text"
                                                    name="billing_country" placeholder="country" />
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <div class="form-group mb-3">
                                                <label class="form-label" for="fuser_city">
                                                    <h6>city</h6>
                                                </label>
                                                <input id="fuser_city" class="form-control" type="text"
                                                    name="billing_city" placeholder="city" />
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <div class="form-group mb-3">
                                                <label class="form-label" for="fuser_state">
                                                    <h6>state</h6>
                                                </label>
                                                <input id="fuser_state" class="form-control" type="text"
                                                    name="billing_state" placeholder="state" />
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <div class="form-group mb-3">
                                                <label class="form-label" for="fuser_zipcode">
                                                    <h6>zipcode</h6>
                                                </label>
                                                <input id="fuser_zipcode" class="form-control" type="text"
                                                    name="billing_zip" placeholder="zipcode" />
                                            </div>
                                        </div>

                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div class="form-group mb-3">
                                                <label class="form-label" for="fuser_address">
                                                    <h6>address</h6>
                                                </label>
                                                <textarea id="fuser_address" class="form-control" name="billing_address"
                                                    cols="30" rows="3" placeholder="Your Address Here"></textarea>
                                            </div>
                                        </div>

                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                <li class="nav-item">
                                                    <a id="offline-tab" @click.prevent="selectpayment('tabset')"
                                                        class="nav-link" href="#offline-payment-list"
                                                        :class="[selectedTab === 'tabset' ? 'active' : '']"
                                                        data-toggle="pill" role="tab"
                                                        aria-controls="offline-payment-list"
                                                        aria-selected="true">Offline Payment</a>
                                                </li>
                                                <li class="nav-item">
                                                    <a id="online-tab" @click.prevent="selectpayment('online')"
                                                        class="nav-link" href="#online-payment-list"
                                                        :class="[selectedTab === 'online' ? 'active' : '']"
                                                        data-toggle="pill" role="tab"
                                                        aria-controls="online-payment-list" aria-selected="false">Online
                                                        Payment</a>
                                                </li>
                                            </ul>

                                            <div class="tab-content" id="pills-tabContent">
                                                <div id="offline-payment-list" :class="[
                                                    selectedTab === 'tabset' ? 'active show' : '',
                                                ]" class="tab-pane fade" role="tabpanel" aria-labelledby="offline-tab">
                                                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <div class="form-group mb-3">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="checkbox"
                                                                    id="flexCheckDefault" />
                                                                <label class="form-check-label" for="flexCheckDefault">
                                                                    <strong>Cash On Delivery</strong>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div id="online-payment-list" :class="[
                                                    selectedTab === 'online' ? 'active show' : '',
                                                ]" class="tab-pane fade" role="tabpanel" aria-labelledby="online-tab">
                                                    <div class="row">
                                                        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                            <div class="form-group mb-3">
                                                                <label class="form-label" for="shop_payment_gateway">
                                                                    <h6>select payment gateway</h6>
                                                                </label>
                                                                <select class="form-select form-control"
                                                                    name="shop_payment_type" id="shop_payment_gateway">
                                                                    <option value="" selected="" disabled="">
                                                                        Select Payment Option
                                                                    </option>
                                                                    <option value="Flutterwave">
                                                                        Flutterwave
                                                                    </option>
                                                                    <option value="Razorpay">Razorpay</option>
                                                                    <option value="Paystack">Paystack</option>
                                                                    <option value="Instamojo">Instamojo</option>
                                                                    <option value="Stripe">Stripe</option>
                                                                    <option value="Paypal">Paypal</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-12">
                                            <button class="btn btn-type-2 mt-20">
                                                Place Order <span></span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="apork-my-cart-wraper">
                                <h3 class="apork-heading-title">your cart</h3>
                                <div class="my-cart-table table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Image</th>
                                                <th>Product Name</th>
                                                <th>Total</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td class="product-thumbnail">
                                                    <img class="img-fluid" src="@/assets/img/product/2.jpg"
                                                        alt="product-image" />
                                                </td>

                                                <td class="product-name">
                                                    <a href="#">Single Vr Headset</a>
                                                </td>
                                                <td class="product-subtotal">
                                                    $1450
                                                    <span class="fa fa-times"></span>
                                                    1 = $1450
                                                </td>
                                            </tr>

                                            <tr>
                                                <td class="product-thumbnail">
                                                    <img class="img-fluid" src="@/assets/img/product/1.jpg"
                                                        alt="product-image" />
                                                </td>

                                                <td class="product-name">
                                                    <a href="#">Black Smartwatch</a>
                                                </td>
                                                <td class="product-subtotal">
                                                    $12
                                                    <span class="fa fa-times"></span>
                                                    1 = $12
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="apork-shipping-wraper">
                                <h3 class="apork-heading-title">shipping charge:</h3>
                                <div class="my-cart-table table-responsive">
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>method</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>
                                                    <input type="radio" checked="" name="shipping_charge" data="0"
                                                        class="shipping-charge" />
                                                </td>
                                                <td>
                                                    Free Shipping
                                                    <strong>($0)</strong>
                                                    <p>Shipment will be within 10-15 Days</p>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <input type="radio" name="shipping_charge" data="10"
                                                        class="shipping-charge" />
                                                </td>
                                                <td>
                                                    Standard Shipping
                                                    <strong>($10)</strong>
                                                    <p>Shipment will be within 5-10 Day.</p>
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <input type="radio" name="shipping_charge" data="20"
                                                        class="shipping-charge" />
                                                </td>
                                                <td>
                                                    Same day delivery
                                                    <strong>($20)</strong>
                                                    <p>Shipment will be within 1 Day.</p>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <div class="apork-cart-summery-wraper">
                                <h3 class="apork-heading-title">cart summery :</h3>
                                <div class="my-cart-table table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th width="33.3%">Subtotal</th>
                                                <td>$1462</td>
                                            </tr>

                                            <tr>
                                                <th width="33.3%">Shiping Cost</th>
                                                <td>+ $<span class="shipping_cost">0</span></td>
                                            </tr>
                                            <tr>
                                                <th width="33.3%">Total</th>
                                                <td>
                                                    $<span class="grand_total" data="1462">1462</span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- apork checkout area end -->
            <!-- footer area start -->
            <FooterTwo />
            <!-- footer area end -->
        </main>
        <!-- main area end -->

        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import Breadcrumb from "../Breadcrumb.vue";
import HeaderOne from "../Header.vue";
import FooterTwo from "../Footer.vue";
export default {
    name: "AboutUs",
    components: { HeaderOne, Breadcrumb, FooterTwo },
    data() {
        return {
            selectedTab: "tabset",
            switchPlan: true,
        };
    },
    mounted() {
        document.addEventListener("scroll", this.topToBottom);
    },
    methods: {
        selectpayment(name) {
            this.selectedTab = name;
        },
        topToBottom() {
            const result = document.querySelector(".material-scrolltop");
            if (
                document.body.scrollTop > window.innerHeight ||
                document.documentElement.scrollTop > window.innerHeight
            ) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
    },
};
</script>

<style>

</style>
