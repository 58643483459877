<template>
  <div id="aprok-faq-area" class="aprok-faq-area">
    <div class="container">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 wow fadeInLeft faq-img-wraper">
          <div class="faq-img">
            <img src="@/assets/img/new/new-faq.png" alt="" />
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 wow fadeInRight">
          <div class="aprok-accordion-wrapper">
            <div class="accordion" id="accordionExample">
              <div class="accordion-item">
                <div class="accordion-header" id="headingOne">
                  <h2 class="mb-0">
                    <a
                      class="btn btn-link"
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne"
                      :aria-expanded="[showfaq === 1 ? 'true' : 'false']"
                      aria-controls="collapseOne"
                      @click.prevent="faqlist(1)"
                      :class="[showfaq === 1 ? '' : 'collapsed']"
                    >
                      서비스 이용에 대한 비용이 있나요?
                    </a>
                  </h2>
                </div>

                <div
                  id="collapseOne"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                  :class="[
                    showfaq === 1
                      ? 'accordion-collapse show'
                      : 'accordion-collapse collapse',
                  ]"
                >
                  <div class="accordion-body">
                    <p class="faq-txt">
                      현재 모든 콘텐츠가 무료로 제공되고 있습니다. 하지만 추후
                      프리미엄 기능 및 특별한 훈련 프로그램에 대해서는 유료
                      구독이 필요할 수 있습니다. 'Plan' 페이지에서 요금에 대한
                      자세한 정보를 확인할 수 있습니다.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <div class="accordion-header" id="headingTwo">
                  <h2 class="mb-0">
                    <a
                      class="btn btn-link"
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      :aria-expanded="[showfaq === 2 ? 'true' : 'false']"
                      aria-controls="collapseTwo"
                      @click.prevent="faqlist(2)"
                      :class="[showfaq === 2 ? '' : 'collapsed']"
                    >
                      내 레벨에 맞는 훈련 프로그램을 어떻게 찾을 수 있나요?
                    </a>
                  </h2>
                </div>

                <div
                  id="collapseTwo"
                  :class="[
                    showfaq === 2
                      ? 'accordion-collapse show'
                      : 'accordion-collapse collapse',
                  ]"
                  aria-labelledby="panelsStayOpen-headingTwo"
                >
                  <div class="accordion-body">
                    <p class="faq-txt">
                      스포바이 서비스는 선수들의 레벨과 목표에 맞는 훈련
                      프로그램을 제공합니다. '자료 찾기'에서 원하는 프로그램을
                      찾아서 도전해보세요!
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <div class="accordion-header" id="headingThree">
                  <h2 class="mb-0">
                    <a
                      class="btn btn-link"
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree"
                      :aria-expanded="[showfaq === 3 ? 'true' : 'false']"
                      aria-controls="collapseThree"
                      @click.prevent="faqlist(3)"
                      :class="[showfaq === 3 ? '' : 'collapsed']"
                    >
                      훈련 제작 툴을 사용하려면 어떻게 해야 하나요?
                    </a>
                  </h2>
                </div>

                <div
                  id="collapseThree"
                  :class="[
                    showfaq === 3
                      ? 'accordion-collapse show'
                      : 'accordion-collapse collapse',
                  ]"
                  aria-labelledby="panelsStayOpen-headingThree"
                >
                  <div class="accordion-body">
                    <p class="faq-txt">
                      훈련 제작 툴은 현재 개발 중에 있으며 추후 '내 훈련 만들기'
                      기능에서 목표를 설정하고 특별한 요구를 추가하여 자신만의
                      훈련 계획을 간편하게 작성할 수 있습니다.
                    </p>
                  </div>
                </div>
              </div>

              <div class="accordion-item">
                <div class="accordion-header" id="headingFour">
                  <h2 class="mb-0">
                    <a
                      class="btn btn-link"
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseFour"
                      :aria-expanded="[showfaq === 4 ? 'true' : 'false']"
                      aria-controls="collapseFour"
                      @click.prevent="faqlist(4)"
                      :class="[showfaq === 4 ? '' : 'collapsed']"
                    >
                      서비스에서 제공하는 데이터는 어떤 출처에서 나오는 건가요?
                    </a>
                  </h2>
                </div>

                <div
                  id="collapseFour"
                  :class="[
                    showfaq === 4
                      ? 'accordion-collapse show'
                      : 'accordion-collapse collapse',
                  ]"
                  aria-labelledby="panelsStayOpen-headingFour"
                >
                  <div class="accordion-body">
                    <p class="faq-txt">
                      우리의 데이터는 주요 축구 대회 및 전문가들의 분석을
                      기반으로 합니다. 우리의 팀은 정확하고 신뢰성 있는 데이터를
                      제공하기 위해 지속적으로 노력하고 있습니다.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FaqOne",
  data() {
    return {
      showfaq: 1,
      sec_title: "",
      sec_para: "",
    };
  },
  methods: {
    faqlist(value) {
      this.showfaq = value;
    },
  },
};
</script>

<style>
.faq-img-wraper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.faq-txt {
  color: black;
}
</style>
