<template>
  <div>
    <HeaderOne />
    <div class="newsletter-container">
      <Breadcrumb title="newsletter" />
      <div class="newsletter-wraper">
        <div class="newsletter-bg">
          <div class="newsletter-img-wraper">
            <img src="@/assets/img/new/news-01.png" alt="" />
          </div>
          <div class="news-txt-wraper">
            <div class="news-txt-bg">
              <h2>
                "스포바이의 뉴스레터를 구독하고<br />
                세계 축구 소식을 빠르게 받아보세요!"
              </h2>
              <p class="txt-01">
                매주 세계 최고의 리그에서 벌어진 경기와 선수들의 돋보이는 활약,
                그리고 이슈가 되는 사건들을 깊이있고 풍부한 분석으로 전달하고
                다가올 주요 경기 일정과 흥미로운 축구 이야기를 집약적으로
                제공합니다.
              </p>
              <p class="txt-02">
                이제 더 이상 스코어만 확인하는 축구 팬이 아닌, 세계 축구의
                흐름을 읽는 지능적인 축구 애호가가 되어 보세요. '풋볼
                뉴스레터'와 함께라면 축구에 대한 이해와 사랑이 더욱 깊어질
                것입니다.
              </p>
              <div class="news-btn-wraper">
                <textarea
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                  class="input-news"
                ></textarea>
                <button
                  class="news-btn"
                  onclick="alert('🎉 구독을 축하드립니다')"
                >
                  구독하기
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderOne from "../Header.vue";
import Footer from "../Footer.vue";
import Breadcrumb from "../Breadcrumb.vue";

export default {
  name: "NewsLetter",
  components: { HeaderOne, Footer, Breadcrumb },
  data() {
    return {};
  },
};
</script>

<style>
.newsletter-container {
  height: 100%;
  background-color: rgb(0, 40, 112, 0.1);
}
.newsletter-wraper {
  display: flex;
  justify-content: center;
}
.newsletter-bg {
  width: 65%;
  height: 600px;
  display: flex;
  border: 1px solid white;
  border-radius: 15px;
  margin: 60px 0;
  background-color: white;
}
.news-txt-bg h2 {
  font-size: 26px;
}
.newsletter-img-wraper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newsletter-img-wraper img {
  padding-left: 10px;
}

.news-txt-wraper {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.news-txt-wraper h2 {
  color: #002870;
  font-weight: 700;
}
.news-txt-bg {
  max-width: 80%;
}
.txt-01 {
  margin-top: 60px;
  margin-bottom: 20px;
}
.txt-01,
.txt-02 {
  font-weight: 500;
  color: #969696;
}
.news-btn-wraper {
  display: flex;
  margin-top: 50px;
}
.news-btn-wraper input {
  width: 400px;
  height: 50px;
  border: 2px solid #eaeaea;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: rgb(16, 24, 40, 0.5);
}
.news-btn {
  width: 110px;
  border: 2px solid #002870;
  background-color: #002870;
  border-radius: 10px;
  height: 50px;
  color: white;
}
.input-news {
  width: 450px;
  height: 50px;
  border: 2px solid #eaeaea;
  border-radius: 10px;
  box-shadow: rgb(16, 24, 40, 0.5);
  margin-right: 30px;
}
</style>
