import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  // 상태
  state: {
    username: "moomoo40",
    password: "1234",
    isLoggedIn: false,
  },
  mutations: {
    login(state, value) {
      console.log(value);
      if (value.username === "moomoo40" && value.password === "1234") {
        state.isLoggedIn = true;
      }
    },
  },
});
// state, getters, mutations, actions, modules
