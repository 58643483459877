<template>
  
    <div class="col-xl-4 col-lg-4 col-md-5 col-sm-12 wow fadeInUp">
        <!-- search wedget -->
        <div class="single-sid-wdg sm-mt-30">
            <form action="#" class="wdg-search-form">
                <input type="text" placeholder="type to search here..." />
                <button class="submit-btn" type="submit">
                    <i class="fa fa-search"></i>
                </button>
            </form>
        </div>
        <!-- archive post wedget -->
        <div class="single-sid-wdg">
            <h4 class="sid-wdg-title">Post Archive</h4>
            <ul class="wdg-post-archive">
                <li>
                    <a href="#">jun 2016<span>(11)</span></a>
                </li>
                <li>
                    <a href="#">aug 2017<span>(02)</span></a>
                </li>
                <li>
                    <a href="#">sep 2018<span>(13)</span></a>
                </li>
                <li>
                    <a href="#">dec 2019<span>(444)</span></a>
                </li>
            </ul>
        </div>
        <!-- latest post wedget -->
        <div class="single-sid-wdg">
            <h4 class="sid-wdg-title">Latest Post</h4>
            <div class="sid-wdg-post">
                <div class="single-wdg-post">
                    <div class="wdg-post-img">
                        <a href="#">
                            <img src="@/assets/img/wdg/wdg-img-1.jpg" alt="blog thumbnail"
                                class="img-fluid" />
                        </a>
                    </div>
                    <div class="wdg-post-content">
                        <h5>
                            <a href="#">Leverage agile frameworks to provide a robust</a>
                        </h5>
                        <span>May 03, 2019</span>
                    </div>
                </div>

                <div class="single-wdg-post">
                    <div class="wdg-post-img">
                        <a href="#">
                            <img src="@/assets/img/wdg/wdg-img-2.jpg" alt="blog thumbnail"
                                class="img-fluid" />
                        </a>
                    </div>
                    <div class="wdg-post-content">
                        <h5>
                            <a href="#">Coding bridges the universal divide. It is the one</a>
                        </h5>
                        <span>Jun 08, 2019</span>
                    </div>
                </div>

                <div class="single-wdg-post">
                    <div class="wdg-post-img">
                        <a href="#">
                            <img src="@/assets/img/wdg/wdg-img-3.jpg" alt="blog thumbnail"
                                class="img-fluid" />
                        </a>
                    </div>
                    <div class="wdg-post-content">
                        <h5>
                            <a href="#">Connects different and countries trying times
                                natio</a>
                        </h5>
                        <span>Aug 11, 2022</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- tags wedget -->
        <div class="single-sid-wdg">
            <h4 class="sid-wdg-title">Post Tags</h4>
            <ul class="list-inline tag-list">
                <li><a href="#">awesome</a></li>
                <li><a href="#">beautiful</a></li>
                <li><a href="#">flat design</a></li>
                <li><a href="#">ios</a></li>
                <li><a href="#">themforest</a></li>
                <li><a href="#">mass</a></li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
name:'SidebarOne',
}
</script>

<style>

</style>