<template>
    <div>
        <!-- header area start -->
        <HeaderOne />
        <!-- header area end -->
        <!-- main area start -->
        <main>
            <!-- breadcrumb area start -->
            <Breadcrumb title="blog list" />
            <!-- breadcrumb area end -->
            <!-- blog area start -->
            <div id="apork-latest-blog-area" class="apork-latest-blog-area mt-110 mb-110">
                <div class="container">
                    <div class="row">
                        <div class="col-xl-8 col-lg-8 col-md-7 col-sm-12">
                            <div class="single-blog-wraper mb-30 wow fadeInUp" v-for="item in count_items"
                                :key="item.id">
                                <div class="blog-img-wraper">
                                    <img :src="item.image" alt="" />
                                    <div class="blog-datetime">
                                        {{ item.date }}
                                    </div>
                                </div>
                                <div class="blog-content-wrap">
                                    <a href="#">{{ item.title }}</a>
                                    <p>{{ item.paragraph }}</p>
                                </div>
                            </div>

                            <div class="apork-pagination mt-30">
                                <nav class="pagination">
                                    <div class="nav-links">
                                        <span aria-current="page" class="page-numbers current">1</span>
                                        <a class="page-numbers" href="#">2</a>
                                        <a class="next page-numbers" href="#"><i class="fa fa-long-arrow-right"></i></a>
                                    </div>
                                </nav>
                            </div>
                        </div>
                        <Sidebar />
                    </div>
                </div>
            </div>
            <!-- blog area end -->
            <!-- footer area start -->
            <FooterTwo />
            <!-- footer area end -->
        </main>
        <!-- main area end -->

        <!-- scrolltop button -->
        <a class="material-scrolltop" href="#"></a>
    </div>
</template>

<script>
import Breadcrumb from "../Breadcrumb.vue";
import Sidebar from "../Sidebar.vue";
import HeaderOne from "../Header.vue";
import FooterTwo from "../Footer.vue";
import img1 from "@/assets/img/blog/blog-4.jpg"
import img2 from "@/assets/img/blog/blog-5.jpg"
import img3 from "@/assets/img/blog/blog-6.jpg"
export default {
    name: "BlogList",
    components: { HeaderOne, Breadcrumb, FooterTwo, Sidebar },
    data() {
        return {
            count_items: [
                {
                    image: img1,
                    date: "15 Dec, 2020",
                    title: "Leverage agile frameworks the printing and industry for Ipsum has been to provide a robust",
                    paragraph:
                        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown pair printer took a galley of type and scrambled it to make a type",
                },
                {
                    image: img2,
                    date: "30 Jan, 2012",
                    title: "Coding bridges the universal divide. It is the one printing and industry for Ipsum has",
                    paragraph:
                        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown pair printer took a galley of type and scrambled it to make a type",
                },
                {
                    image: img3,
                    date: "21 Mar, 1968",
                    title: "Connects different and countries trying times natio the divide. It is the one printing and",
                    paragraph:
                        "simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown pair printer took a galley of type and scrambled it to make a type",
                },
            ],
        }
    },
    mounted() {
        document.addEventListener('scroll', this.topToBottom)
    },
    methods: {
        topToBottom() {
            const result = document.querySelector('.material-scrolltop')
            if (document.body.scrollTop > window.innerHeight || document.documentElement.scrollTop > window.innerHeight) {
                result.classList.add("reveal");
            } else {
                result.classList.remove("reveal");
            }
        },
    }
};
</script>

<style>

</style>
