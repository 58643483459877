<template>
  <div>
    <HeaderOne />
    <div class="product-detail-best">
      <Breadcrumb
        title="GRAND OPENING"
        sub-title="모든 자료를 무료로 즐겨보세요."
      />

      <div class="contents-box-wraper">
        <div class="contents-box">
          <!-- 사이드 바 시작 -->
          <div class="side-nav-wraper">
            <div class="side-nav-line"></div>
            <h3 class="side-nav-title">인기 카테고리</h3>
            <ul class="side-nav-item">
              <li class="nav-li">
                <a href="/product-list" class="nav-li-02-a">전체</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-best" class="nav-li-03-a">BEST</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-program" class="nav-li-04-a"
                  >프로그램</a
                ><span>></span>
              </li>
              <div class="side-nav-item-bg-01">
                <li class="nav-li-01">
                  <a href="/product-list-education" class="nav-li-01-a"
                    >교육 강의</a
                  ><span>></span>
                </li>
              </div>
              <li class="nav-li">
                <a href="/product-list-pro" class="nav-li-05-a">프로 구단</a
                ><span>></span>
              </li>
              <li class="nav-li">
                <a href="/product-list-youth" class="nav-li-06-a">유소년</a
                ><span>></span>
              </li>
            </ul>
          </div>

          <!-- 메인 컨텐츠 시작 -->
          <div class="main-con-wraper">
            <div class="main-con-bg">
              <product
                v-for="(product, index) in products"
                :key="`product${index}`"
                :product="product"
              />
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  </div>
</template>

<script>
import HeaderOne from "../Header.vue";
import Footer from "../Footer.vue";
import Breadcrumb from "../Breadcrumb.vue";
import Product from "../Product.vue";

export default {
  name: "ProductListEducation",
  components: { HeaderOne, Footer, Breadcrumb, Product },
  data() {
    return {
      products: [
        {
          id: 1,
          title: "초등학생 기본 볼 마스터리 훈련법",
          description:
            "초등 저학년 축구부 혹은 초보자 필수! 11가지 기본 감각 훈련",
          image: require("/src/assets/img/new/youtube-01.png"),
          image2: require("/src/assets/img/sub-img/youtube-01-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-01-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=s155EZW3qzs&t=7s",
          downloadUrl: "/video/초등학생 기본 볼 마스터리 훈련법.mp4",
          length: "03:06",
          number: "355",
          rank: "무료",
          strength: "중하",
          participation: "1명",
          age: "8 ~ 13",
        },
        {
          id: 2,
          title: "초보자 필수 훈련",
          description: "내가 축구 초보라면? 반드시 이 영상을 보세요!",
          image: require("/src/assets/img/new/youtube-02.png"),
          image2: require("/src/assets/img/sub-img/youtube-02-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-02-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=CHhpEGuPsqk&t=3s",
          downloadUrl: "/video/초보자 필수 훈련.mp4",
          length: "05:47",
          number: "366",
          rank: "무료",
          strength: "중",
          participation: "1명",
          age: "10 ~ 16",
        },
        {
          id: 3,
          title: "[30분 코스] 패스와 컨트롤 훈련",
          description: "패스와 컨트롤 간단한 훈련 10가지",
          image: require("/src/assets/img/new/youtube-03.png"),
          image2: require("/src/assets/img/sub-img/youtube-03-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-03-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=F7e0QpvF9fA&t=7s",
          downloadUrl: "/video/[30분 코스] 패스와 컨트롤 훈련.mp4",
          length: "06:36",
          number: "204",
          rank: "무료",
          strength: "중",
          participation: "1명",
          age: "10 ~ 20",
        },
        {
          id: 4,
          title: "[기본기] 인사이드 패스 강좌",
          description: "축구에서 가장 중요한 기본기-패스",
          image: require("/src/assets/img/new/youtube-04.png"),
          image2: require("/src/assets/img/sub-img/youtube-04-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-04-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=cR9Dspq-Kjo&t=311s",
          downloadUrl: "/video/[기본기] 인사이드 패스 강좌.mp4",
          length: "06:25",
          number: "109",
          rank: "무료",
          strength: "하",
          participation: "1 ~ 2명",
          age: "8 ~ 20",
        },
        {
          id: 5,
          title: "프로 19년차가 알려주는 롱킥",
          description: "롱킥은 힘으로만 차는 게 아니라 임팩트가 중요합니다.",
          image: require("/src/assets/img/new/youtube-05.png"),
          image2: require("/src/assets/img/sub-img/youtube-05-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-05-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=R5JNBLYni70&t=87s",
          downloadUrl: "/video/프로 19년차가 알려주는 롱킥.mp4",
          length: "12:09",
          number: "52",
          rank: "무료",
          strength: "중하",
          participation: "1 ~ 2명",
          age: "17 ~",
        },
        {
          id: 6,
          title: "[세뇰킴] 사비 알론소처럼 롱킥 차는법",
          description: "6명씩 한 그룹을 만들어서 2대1 패스 훈련을 하는 방법은?",
          image: require("/src/assets/img/new/youtube-06.png"),
          image2: require("/src/assets/img/sub-img/youtube-06-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-06-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=H83K7Zyu0P4",
          downloadUrl: "/video/[세뇰킴] 사비 알론소처럼 롱킥 차는법.mp4",
          length: "02:01",
          number: "409",
          rank: "무료",
          strength: "중하",
          participation: "1명",
          age: "16 ~ 20",
        },
        {
          id: 7,
          title: "[세뇰킴] 골대 앞에서 정확하게 슈팅하는 법",
          description: "6명씩 한 그룹을 만들어서 2대1 패스 훈련을 하는 방법은?",
          image: require("/src/assets/img/new/youtube-07.png"),
          image2: require("/src/assets/img/sub-img/youtube-07-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-07-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=y1C07lp3I38&t=31s",
          downloadUrl: "/video/[세뇰킴] 골대 앞에서 정확하게 슈팅하는 법.mp4",
          length: "02:33",
          number: "102",
          rank: "무료",
          strength: "중",
          participation: "그룹 - 6명",
          age: "13 ~ 20",
        },
        {
          id: 8,
          title: "[조세민의 레슨] 드리블 강좌",
          description: "100% 상대를 이길 수 있는 드리블 기술",
          image: require("/src/assets/img/new/youtube-08.png"),
          image2: require("/src/assets/img/sub-img/youtube-08-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-08-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=VOi5ZqWxpC4&t=3s",
          downloadUrl: "/video/[조세민의 레슨] 드리블 강좌.mp4",
          length: "04:05",
          number: "262",
          rank: "무료",
          strength: "중하",
          participation: "1 ~ 2명",
          age: "10 ~ 16",
        },
        {
          id: 9,
          title: "[서벤사커] 중거리 슈팅",
          description: "무회전 중거리 슈팅을 하는 방법",
          image: require("/src/assets/img/new/youtube-13.png"),
          image2: require("/src/assets/img/sub-img/youtube-13-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-13-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=94glZJjPexo",
          downloadUrl: "/video/[서벤사커] 중거리 슈팅.mp4",
          length: "06:18",
          number: "442",
          rank: "무료",
          strength: "중하",
          participation: "1 ~ 2명",
          age: "17 ~ 20",
        },
        {
          id: 10,
          title: "[레시피] 기본기 훈련",
          description: "축구선수들 모두가 하고 있는 기본기 강좌",
          image: require("/src/assets/img/new/youtube-14.png"),
          image2: require("/src/assets/img/sub-img/youtube-14-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-14-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=L4AmNlStkHE&t=262s",
          downloadUrl: "/video/[레시피] 기본기 훈련.mp4",
          length: "06:42",
          number: "370",
          rank: "무료",
          strength: "중하",
          participation: "2명",
          age: "10 ~ 20",
        },
        {
          id: 11,
          title: "[싸커최] 축구 기본기",
          description: "현직 유소년 감독이 알려주는 축구의 기본기",
          image: require("/src/assets/img/new/youtube-15.png"),
          image2: require("/src/assets/img/sub-img/youtube-15-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-15-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=u04FGeu3QCk&t=11s",
          downloadUrl: "/video/[싸커최] 축구 기본기.mp4",
          length: "06:41",
          number: "344",
          rank: "무료",
          strength: "중",
          participation: "1 ~ 2명",
          age: "8 ~ 13",
        },
        {
          id: 12,
          title: "[싸커최] 킥 & 컨트롤 ",
          description: "현직 유소년 감독이 알려주는 축구의 기본기",
          image: require("/src/assets/img/new/youtube-16.png"),
          image2: require("/src/assets/img/sub-img/youtube-16-01.png"),
          image3: require("/src/assets/img/sub-img/youtube-16-02.png"),
          youtubeUrl: "https://www.youtube.com/watch?v=9SC8R-uHfo4&t=624s",
          downloadUrl: "/video/[싸커최] 킥 & 컨트롤 .mp4",
          length: "12:31",
          number: "35",
          rank: "무료",
          strength: "중",
          participation: "1 ~ 2명",
          age: "11 ~ 20",
        },
      ],
    };
  },
};
</script>
<style>
.product-detail {
  height: 100%;
}
.contents-box-wraper {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}
.contents-box {
  width: 80%;
  height: 80%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

/* 사이드 바 */
.side-nav-wraper {
  margin-top: 40px;
  width: 11.5%;
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.side-nav-line {
  width: 100%;
  height: 5px;
  background-color: #002870;
  margin-bottom: 15px;
}
.side-nav-title {
  font-size: 25px;
  font-weight: 700;
}
.side-nav-item-bg-01 {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: rgb(0, 40, 112, 0.1);
}
.side-nav-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 100%;
}
.side-nav-item li {
  padding: 10px 0;
  font-size: 17px;
  font-weight: 500;
  width: 80%;
}

.nav-li {
  display: flex;
  justify-content: space-between;
}
.nav-li-01 {
  color: #002870;
  display: flex;
  justify-content: space-between;
}
.nav-li-01-a {
  color: #002870;
}
.nav-li-02-a,
.nav-li-03-a,
.nav-li-04-a,
.nav-li-05-a,
.nav-li-06-a {
  color: black;
}

/* 메인 콘텐츠 */
.main-con-wraper {
  margin-top: 40px;
  width: 80%;
  height: 100%;
}
.main-con-bg {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

/* 콘텐츠 01 */
</style>
