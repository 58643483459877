<template>
  <main class="flex">
    <!-- Content -->
    <div class="w-full md:w-1/2">
      <div class="min-h-screen h-full flex flex-col justify-center">
        <div class="px-5 sm:px-6 py-8">
          <div class="w-full max-w-md mx-auto">
            <!-- Site branding -->
            <div class="mb-6">
              <!-- Logo -->
              <router-link
                class="inline-flex text-blue-600 transition duration-150 ease-in-out"
                to="/"
                aria-label="Cruip"
              >
                <img
                  src="../../assets/img/login/logo-01.png"
                  class="w-13 h-8"
                  style="margin-bottom: 20px;"
                />
              </router-link>
            </div>

            <!-- Form -->
            <form>
              <div class="space-y-4">
                <div>
                  <label class="block text-sm font-medium mb-1" for="username"
                    >ID <span class="text-rose-500">*</span></label
                  >
                  <input
                    id="username"
                    class="form-input py-2 w-full input-login-01"
                    type="text"
                    placeholder="아이디를 입력해 주세요."
                    required
                    v-model="username"
                  />
                </div>
                <div>
                  <label class="block text-sm font-medium mb-1" for="password"
                    >Password<span class="text-rose-500">*</span></label
                  >
                  <input
                    id="password"
                    class="form-input py-2 w-full input-login-02"
                    type="password"
                    placeholder="비밀번호를 입력해 주세요."
                    required
                    v-model="password"
                  />
                </div>
                <div class="space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                  <!-- <div class="sm:w-1/2">
                    <label class="block text-sm font-medium mb-1" for="city">City <span class="text-rose-500">*</span></label>
                    <input id="city" class="form-input py-2 w-full" type="text" required />
                  </div>
                  <div class="sm:w-1/2">
                    <label class="block text-sm font-medium mb-1" for="pcode">Postal Code <span class="text-rose-500">*</span></label>
                    <input id="pcode" class="form-input py-2 w-full" type="text" required />
                  </div> -->
                </div>
                <!-- <div>
                  <label class="block text-sm font-medium mb-1" for="address"
                    >Message <span class="text-rose-500">*</span></label
                  >
                  <textarea
                    id="address"
                    class="form-input w-full"
                    cols="30"
                    rows="8"
                    required
                  />
                </div> -->
                <!-- <div>
                  <label class="block text-sm font-medium mb-1" for="country">Country <span class="text-rose-500">*</span></label>
                  <select id="country" class="form-select py-2 w-full" required>
                    <option>United States</option>
                    <option>United Kingdom</option>
                    <option>Germany</option>
                  </select>
                </div> -->
              </div>
              <div class="mt-6">
                <button
                  class="btn-sm w-full text-sm text-white bg-blue-600 hover:bg-blue-700 group py-3 mb-0"
                  @click.prevent="login"
                >
                  LOGIN
                  <span
                    class="tracking-normal text-blue-300 group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-1"
                    >-&gt;</span
                  >
                </button>
              </div>
              <div class="join-wraper-01">
                <p class="join-text">아직 회원이 아니신가요?</p>
                <a href="/join" class="join-btn">회원가입하러 가기</a>
              </div>
              <div class="join-wraper-02">
                <p class="join-text">암호를 잃어버리셨나요?</p>
                <a href="/" class="join-btn">비밀번호 찾기</a>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Right side -->
    <div
      class="relative hidden md:block md:w-1/2 bg-slate-900"
      aria-hidden="true"
    >
      <!-- Bg image -->
      <div class="absolute inset-0" data-aos="fade">
        <img
          class="opacity-10 w-full h-full object-cover"
          src="../../assets/img/login/opinion-02.jpg"
          width="760"
          height="900"
          alt="Background"
        />
      </div>

      <!-- Quote -->
      <div class="min-h-screen h-full flex flex-col justify-center">
        <div class="px-5 sm:px-6">
          <div class="w-full max-w-lg mx-auto">
            <h2
              class="h3 md:text-4xl font-playfair-display text-slate-100 mb-4"
            >
              Interview.
            </h2>
            <div class="space-y-3">
              <svg
                class="fill-blue-600"
                width="20"
                height="16"
                viewBox="0 0 20 16"
              >
                <path
                  d="M2.76 16c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.613-2.272-1.748-2.272s-2.27.726-3.283 1.64C3.16 6.439 5.613 3.346 9.571.885L9.233 0C3.466 2.903 0 7.732 0 12.213 0 14.517.828 16 2.76 16Zm10.43 0c2.577 0 5.154-3.219 5.154-5.996 0-1.357-.614-2.272-1.749-2.272-1.135 0-2.27.726-3.282 1.64.276-2.934 2.73-6.027 6.687-8.488L19.663 0c-5.767 2.903-9.234 7.732-9.234 12.213 0 2.304.829 3.787 2.761 3.787Z"
                />
              </svg>
              <blockquote class="text-slate-400 italic">
                No matter where you are struggling, keep trying and keep going.
                Most importantly, you should always believe in yourself. Be
                brave. Move forward for your dream. You will win it.
              </blockquote>
            </div>
            <div class="flex items-center mt-4">
              <router-link to="#0">
                <!-- <img class="rounded-full shrink-0 mr-3" src="../images/customer-avatar-04.jpg" width="32" height="32" alt="Customer Avatar 04" /> -->
              </router-link>
              <div class="font-medium">
                <span class="text-slate-200">Harry Kane</span>
                <span class="text-slate-600"> · </span>
                <span class="text-slate-500">Player, FC Bayern.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  /* eslint-disable */
  name: "Login",
  data() {
    return {
      username: "",
      password: "",
    };
  },
  methods: {
    login() {
      const data = {
        username: this.username,
        password: this.password,
      };
      this.$store.commit("login", data);
      this.$router.push("/");
    },
  },
};
</script>

<style scoped>
.input-login-01 {
  padding-left: 15px;
  height: 50px;
  border: 2px solid #eaeaea;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  box-shadow: rgb(16, 24, 40, 0.5);
}
.input-login-02 {
  padding-left: 15px;
  height: 50px;
  border: 2px solid #eaeaea;
  border-radius: 10px;
  margin-top: 10px;
  box-shadow: rgb(16, 24, 40, 0.5);
}
.input-login-01:focus {
  border: 3px solid #2463eb;
}
.input-login-02:focus {
  border: 3px solid #2463eb;
}

.join-wraper-01 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 50px;
}
.join-wraper-02 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 5px;
}
.join-text {
  font-size: 15px;
}
.join-btn {
  font-size: 15px;
  font-weight: 700;
  text-decoration: underline;
  color: #2463eb;
}
</style>
