<template>
  <div>
    <HeaderOne />
    <Breadcrumb title="Q&A" />
    <div
      style="
        padding: 100px 0px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      "
      class="row"
    >
      <div class="faq-wraper">
        <Faq />
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import HeaderOne from "../Header.vue";
import Footer from "../Footer.vue";
import Faq from "../Faq.vue";
import Breadcrumb from "../Breadcrumb.vue";

export default {
  name: "FaqPage",
  components: { HeaderOne, Footer, Faq, Breadcrumb },
  data() {
    return {};
  },
};
</script>

<style>
.faq-wraper {
  width: 100%;
  height: 700px;
  border: 1px solid white;
  border-radius: 15px;
  margin: 60px 0;
  background-color: white;
}
</style>
