<template>
  <div>
    <!-- preloaders start -->
    <div class="preloader" v-if="showloading">
      <div class="spinner">
        <div class="rect1"></div>
        <div class="rect2"></div>
        <div class="rect3"></div>
        <div class="rect4"></div>
        <div class="rect5"></div>
      </div>
    </div>
    <!-- preloaders end -->
    <header>
      <!-- header area start -->
      <div class="header-area">
        <div class="container">
          <div class="row">
            <!-- logo start -->
            <div
              class="col-xl-2 col-lg-2 col-md-2 col-sm-3"
              style="display: flex; align-items: center"
            >
              <div class="header-logo">
                <router-link to="/">
                  <img src="@/assets/img/Logo.png" alt="img" />
                </router-link>
              </div>
            </div>
            <!-- main menu area start -->
            <div
              class="col-xl-10 col-lg-10 col-md-10 col-sm-9"
              style="
                display: flex;
                justify-content: flex-end;
                align-items: flex-end;
              "
            >
              <div class="header-top-right float-right">
                <!-- <ul class="header-search" @click="searchshow">
                  <li>
                    <a id="search-btn" href="#"><i class="fa fa-search"></i></a>
                  </li>
                </ul> -->

                <!-- <div class="header-top-btn">
                  <a href="#" class="btn btn-type-1">
                    oad <i class="fa fa-download"></i></a>
                </div> -->

                <!-- mobile menu with canvus start -->
                <div
                  class="offcanvas offcanvas-start"
                  tabindex="-1"
                  id="offcanvasExample"
                  aria-labelledby="offcanvasExampleLabel"
                >
                  <div class="offcanvas-header">
                    <div class="apork-canvas-logo">
                      <router-link to="/">
                        <img
                          src="@/assets/img/Logo.png"
                          walt="img"
                          width="200"
                        />
                      </router-link>
                    </div>
                    <button
                      @click="mobilemenuclose"
                      type="button"
                      class="btn-close"
                      data-bs-dismiss="offcanvas"
                      aria-label="Close"
                    ></button>
                  </div>
                  <div class="offcanvas-body apork-offcanvas-mobile-menu-area">
                    <nav>
                      <ul>
                        <li class="active">
                          <router-link to="/product-list"
                            >자료 찾기</router-link
                          >
                        </li>
                        <li>
                          <a href="#"
                            >고객 지원 <i class="fa fa-angle-down"></i
                          ></a>
                          <ul class="sub-menu text-left">
                            <li>
                              <router-link to="/faq">FAQ</router-link>
                            </li>
                            <li>
                              <router-link to="/plan">가격 정책</router-link>
                            </li>
                            <li>
                              <router-link to="/newcontact"
                                >문의하기</router-link
                              >
                            </li>
                          </ul>
                        </li>
                        <li>
                          <router-link to="/newsletter">뉴스레터</router-link>
                        </li>
                      </ul>
                    </nav>
                    <div class="offcanvas-social">
                      <ul class="text-center">
                        <li>
                          <a href="#"><i class="fa fa-facebook-f"></i></a>
                        </li>
                        <li>
                          <a href="#"><i class="fa fa-twitter"></i></a>
                        </li>
                        <li>
                          <a href="#"><i class="fa fa-instagram"></i></a>
                        </li>
                        <li>
                          <a href="#"><i class="fa fa-dribbble"></i></a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <!-- mobile menu with canvus end -->

                <div
                  class="mobile-menu d-xl-none d-lg-none"
                  @click="mobilemenushow"
                >
                  <div class="toggle-btn"><i class="fa fa-bars"></i></div>
                </div>
              </div>

              <div class="main-menu-area float-right">
                <div class="main-menu">
                  <nav class="nav">
                    <ul>
                      <li class="active">
                        <router-link to="/product-list">자료 찾기</router-link>
                      </li>
                      <li>
                        <a href="#"
                          >고객 지원 <i class="fa fa-angle-down"></i
                        ></a>
                        <ul class="sub-menu text-left">
                          <li>
                            <router-link to="/faq">FAQ</router-link>
                          </li>
                          <li>
                            <router-link to="/plan">가격 정책</router-link>
                          </li>
                          <li>
                            <router-link to="/newcontact">문의하기</router-link>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <router-link to="/newsletter">뉴스레터</router-link>
                      </li>
                      <!-- <li>
                        <router-link to="/shop">Shop</router-link>
                      </li> -->
                      <!-- <li>
                        <a href="#">Blog <i class="fa fa-angle-down"></i></a>
                        <ul class="sub-menu text-left">
                          <li>
                            <router-link to="/blog-list">Blog List</router-link>
                          </li>
                          <li>
                            <router-link to="/blog-grid">Blog Grid</router-link>
                          </li>
                        </ul>
                      </li> -->
                      <!-- <li>
                        <router-link to="/contact">Contact</router-link>
                      </li> -->
                    </ul>
                    <div v-if="!$store.state.isLoggedIn" class="login">
                      <a href="/login">회원가입 / 로그인</a>
                    </div>
                    <div
                      v-else
                      style="
                        display: flex;
                        align-items: center;
                        padding-left: 30px;
                        text-align: right;
                      "
                    >
                      <div style="margin-right: 10px">
                        {{ $store.state.username }} 님 반갑습니다.
                      </div>
                      <div
                        style="
                          cursor: pointer;
                          padding: 7px 20px;
                          border-radius: 20px;
                          background-color: #002870;
                          color: white;
                        "
                        @click="logout"
                      >
                        로그아웃
                      </div>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
            <!-- main menu area end -->
          </div>
        </div>
      </div>
      <!-- header area end -->
      <!-- search form start -->
      <div class="search-form-area" id="search-overlay">
        <div class="search-form-centered">
          <div id="search-box">
            <i
              @click="searchclose"
              id="close-btn"
              class="fa fa-times fa-2x"
            ></i>
            <form class="search-form">
              <input
                class="form-control"
                placeholder="Type your text"
                type="text"
              />
              <button type="submit">
                <span>Search</span>
              </button>
            </form>
          </div>
        </div>
      </div>
      <!-- search form end -->
    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderOne",
  data() {
    return {
      showloading: true,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.stickMenu);
  },
  created() {
    this.preLoading();
    document.addEventListener("scroll", this.topToBottom);
  },
  methods: {
    preLoading() {
      setTimeout(() => {
        this.showloading = false;
      }, 2000);
    },
    mobilemenushow() {
      const result = document.querySelector(".offcanvas-start");
      result.classList.add("show");
    },
    mobilemenuclose() {
      const result = document.querySelector(".offcanvas-start");
      result.classList.remove("show");
    },
    searchshow() {
      const result = document.querySelector(".search-form-area");
      result.style.display = "block";
    },
    searchclose() {
      const result = document.querySelector(".search-form-area");
      result.style.display = "none";
    },

    toggleSidebar() {
      this.sidebar = !this.sidebar;
    },
    stickMenu() {
      const result = document.querySelector(".header-area");
      if (
        document.body.scrollTop > 100 ||
        document.documentElement.scrollTop > 100
      ) {
        result.classList.add("sticky");
      } else {
        result.classList.remove("sticky");
      }
    },
    logout() {
      this.$store.state.isLoggedIn = false;
      this.$router.push("/login");
    },
  },
};
</script>

<style></style>
