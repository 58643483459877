<template>
  <div
    id="apork-testimonial-area"
    class="apork-testimonial-area pt-110 pb-110 mt-20 mb-110 wow fadeInUp"
  >
    <div class="testimonial-overlay"></div>
    <div class="container position-relative">
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12">
          <div class="testimonial-wraper">
            <carousel
              :autoplay="false"
              :nav="false"
              :loop="true"
              :dots="true"
              :smartSpeed="1000"
              :responsive="{
                0: { items: 1 },
                600: { items: 1 },
                1000: { items: 2 },
              }"
              responsive-base-element="body"
            >
              <div class="single-testimonial">
                <div class="testimonial-img">
                  <img src="@/assets/img/testimonial/user-01.png" alt="" />
                </div>
                <div class="testimonial-content">
                  <div class="title-desig">
                    <h3>오지훈</h3>
                    <h4>축구 인플루언서</h4>
                  </div>
                  <p>
                    다양한 훈련 프로그램과 트릭, 그리고 세계 각지의 다른
                    골키퍼들의 경험을 공유할 수 있는 기회를 제공하고 있어,
                    나에게 새로운 아이디어와 자극을 줬어요. 정말 감사합니다!
                  </p>
                </div>
              </div>
              <div class="single-testimonial">
                <div class="testimonial-img">
                  <img src="@/assets/img/testimonial/user-02.png" alt="" />
                </div>
                <div class="testimonial-content">
                  <div class="title-desig">
                    <h3>서현숙</h3>
                    <h4>축구선수</h4>
                  </div>
                  <p>
                    나에게 맞춤형 훈련과 전략을 제공하는 데에 큰 도움이 되고
                    있어요. 여성 축구에 특화된 콘텐츠와 다양한 훈련법은 내 실력
                    향상에 큰 도움이 되고 있어 기뻐합니다.
                  </p>
                </div>
              </div>
              <div class="single-testimonial">
                <div class="testimonial-img">
                  <img src="@/assets/img/testimonial/user-03.png" alt="" />
                </div>
                <div class="testimonial-content">
                  <div class="title-desig">
                    <h3>김재웅</h3>
                    <h4>축구 감독</h4>
                  </div>
                  <p>
                    팀에서 사용하는 통합 시스템을 찾고 있었는데, 스포바이는
                    우리의 요구를 완벽하게 충족시켜주었습니다. 다양한 훈련법과
                    프로그램을 한 곳에서 접근할 수 있어서 정말 효율적이에요.
                    우리 팀 전체가 만족하고 있습니다
                  </p>
                </div>
              </div>
              <div class="single-testimonial">
                <div class="testimonial-img">
                  <img src="@/assets/img/testimonial/user-04.png" alt="" />
                </div>
                <div class="testimonial-content">
                  <div class="title-desig">
                    <h3>이현성</h3>
                    <h4>축구선수</h4>
                  </div>
                  <p>
                    스포바이를 이용하면서 선수로서의 기술적인 성장 뿐만 아니라,
                    축구에 대한 이해도도 높아지고 있습니다. 풍부한 데이터와
                    전문가들의 조언은 정말 가치 있어요. 감사합니다.
                  </p>
                </div>
              </div>
            </carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel2";
export default {
  name: "TestimonialOne",
  components: { carousel },
};
</script>

<style></style>
